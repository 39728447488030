import store from '../store/index'
import VueRouter from 'vue-router'

export function validaLoginStep(to, from, next) {

    if (store.getters.validaTenent == '') {
        if($(window).width() >= 768){
            next('/unidade')
        }else{
            next('/unidadeCpf')
        }
    } else {
        next()
    }

}
export function redirectEnter(to, from, next) {
    if (store.getters.validateCaixa && store.getters.loginAutenticado) {
        next('/')
    } else {
        next()
    }
}
export function requireAuth(to, from, next) {
    if (store.getters.getUser.profile != 3 && store.getters.getterToken || store.getters.validateCaixa && store.getters.getterToken) {
        next()
    } else {
        if($(window).width() >= 768){
            next('/login')
        }else{
            next('/unidadeCpf')
        }
    }
}
export function validaPedidos(to, from, next) {
    if (store.state.pedidos.pedidos != '') {
        next()
    } else {
        next('/')
    }
}
export function validaVendedor(to, from, next) {
    if (store.getters.getUser.profile == 3) {
        next()
    } else {
        next('/')
    }
}
export function validaFranqueado(to, from, next) {
    if (store.getters.getUser.profile == 2) {
        next()
    } else {
        next('/')
    }
}
export function nivelAcesso(to, from, next) {
    if (store.getters.getUser.profile != 3 && store.getters.getterToken) {
        next()
    } else {
        next('/')
    }

}
export function master(to, from, next) {
    if (store.getters.getUser.profile == 1) {
        next()
    } else {
        next('/')
    }
}
