<template>
	<div class="px-1 menu">
		<a
			class="dropdown-item d-flex align-items-center mb-1 py-0"
			href="#"
			v-for="(item , index) in installment"
			:key="index"
			@mouseenter="dropdownInstallment(item)"
		>
			<div class="col-md-10">
				<p class="mb-0">{{item.name}}</p>
			</div>
			<div class="col-md-2 border_shadown">
				<i class="fas fa-chevron-right"></i>
			</div>
		</a>
	</div>
</template>

<script>
	export default {
		props: ["installment"],
		data() {
			return {};
		},
		mounted() {},
		methods: {
			dropdownInstallment(val) {
				// console.log(this.$store.state.installment.menuSubmenu)
				// console.log(this.$store.state.suporte.suporteCategoria)
				// console.log(val.listInstallment)
				this.$emit("dropdownInstallment", this.sortArrayServices(val.listInstallment));
			},
		},
	};
</script>

<style scoped>
p {
	white-space: initial;
}
.border_shadown {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.border_shadown:before {
	box-shadow: -3px 0px 7px -3px rgba(33, 33, 33, 0.2);
	content: " ";
	height: 100%;
	left: 0px;
	position: absolute;
	top: 0;
	width: 100%;
}
i {
	float: right;
}
.dropdown-item {
	padding: 0.25rem 0.5rem;
	background-color: #ffffff;
	height: 60px;
	color: #868686;
	font-size: 12px;
	transition: all 0.2s linear;
	direction: ltr;
}
.newService {
	background-color: #357eb8;
	padding: 0.25rem 0.5rem;

	height: 60px;
	color: #ffffff;
	font-size: 12px;
	transition: all 0.2s linear;
	direction: ltr;
}
.dropdown-item:hover {
	background-color: #f38235;
	color: #ffffff;
}
.menu {
	max-height: 70vh;
	overflow: auto;
	overflow-x: hidden;
	direction: rtl;
	width: 250px;
}
.menu::-webkit-scrollbar {
	width: 5px;
	background: #fff;
}

.menu::-webkit-scrollbar-thumb {
	background-color: #f38235;
	outline: 1px solid slategrey;
}
.menu::-webkit-scrollbar-track {
	background-color: #fff;
}
</style>
