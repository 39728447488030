import HTTP from '@/api_system'
export default {
    state: {


        suporteCategoria: [],


    },
    mutations: {

        set_suporteCategoria(state, val) {
            state.suporteCategoria = val
        },


    },
    actions: {

        getSuporte({
            commit,
            getters
        }) {
            fetch(`${process.env.VUE_APP_ROOT_API}/api/services/app/Suport/GetSuporteByCategoryDto`, {
                method: "GET",
                headers: { Authorization: `bearer ${getters.getterToken}` }
            }).then(response => response.json()).then(response => {
              var arr = response.result.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1
                }
                if (a.name > b.name) {
                  return 1
                }
                return 0
              })

              commit('set_suporteCategoria', arr)

            }).catch(response => {

            })

        },
        
    },
    getters: {

    }

}
