export default {
  state: {
    progress: 0,
    edit: false
  },
  mutations: {
    progressChange(state, newValue) {
      return (state.progress = newValue);
    },
    editeChange(state, newValue) {
      return (state.edit = newValue);
    },
  },
  getters: {
    progressGet(state) {
      return state.progress;
    },
    editGet(state) {
      return state.edit;
    },
  }
};
