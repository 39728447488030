var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[(!_vm.get_tenant.isDispatcher)?_c('div',{staticClass:"menuSubCont"},_vm._l((_vm.filterServicos),function(item,index){return _c('a',{key:index,class:("dropdown-item d-flex align-items-center mb-0 py-0 " + (_vm.concatName(
          item.serviceName
        ))),attrs:{"href":"#"},on:{"click":function($event){_vm.hoverMenuMobile(item, _vm.concatName(item.serviceName))},"mouseover":function($event){_vm.hoverMenu(item, _vm.concatName(item.serviceName))}}},[_c('div',{staticClass:"col-md-10 d-flex align-items-center justify-content-between p-0",attrs:{"id":"contentCont"}},[_c('p',{staticClass:"m-0 col-7 "},[_vm._v(_vm._s(item.serviceName))]),_c('p',{staticClass:"m-0 col-4"},[_vm._v(_vm._s(_vm.valueConvert(item.value)))]),(
            _vm.getNotificationStatus &&
              _vm.getNotificationStatus.notificationsServices &&
              _vm.getNotificationStatus.notificationsServices.some(
                function (s) { return s.serviceId == item.id && s.notification; }
              )
          )?_c('p',{staticClass:"notificationAccompany m-0 "}):_c('p')]),_vm._m(0,true)])}),0):_vm._e(),(_vm.get_tenant.isDispatcher)?_c('a',{class:("newService d-flex align-items-center mb-1 p-0 " + (_vm.concatName(
        'Solicitar'
      ))),attrs:{"href":"#"},on:{"click":function($event){_vm.hoverMenuMobile(_vm.servicos.at(-1), _vm.concatName('Solicitar'))},"mouseover":function($event){_vm.hoverMenu(_vm.servicos.at(-1), _vm.concatName('Solicitar'))}}},[_vm._m(1),_c('div',{staticClass:"notificationController px-2 mx-2"},[_vm._v(" "+_vm._s(_vm.getNotificationStatus.notificationsController)+" ")])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-2 border_shadown"},[_c('i',{staticClass:"fas fa-chevron-right"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-10 d-flex align-center justify-content-between"},[_c('p',{staticClass:"mb-0 controller"},[_vm._v("Controle de Solicitação")])])}]

export { render, staticRenderFns }