import HTTP from '@/api_system'
export default {
    state: {

    },
    mutations: {


    },
    actions: {


        getPessoas({ commit, getters }) {
            return new Promise((resolve, reject) => {
                fetch(`${process.env.VUE_APP_ROOT_API}/api/services/app/User/GetListOfUsers`, {
                    method: "GET",
                    headers: { Authorization: `bearer ${getters.getterToken}` }
                }).then(response => response.json()).then(response => {
                    var resultado = response.result.filter(item => {

                        return item.id != getters.getUser.id
                    })

                    resolve(resultado)

                }).catch(error => {
                    reject({ erro: true, msg: error })
                })
            })
        },
        newPessoa({ commit, getters }, obj) {
            return new Promise((resolve, reject) => {
                HTTP.post("services/app/User/CreateUser", obj, {
                    headers: {
                        Authorization: `Bearer ${getters.getterToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(
                    response => {
                        resolve({ success: true, msg: 'Pessoa cadastrada com sucesso!' })
                    }
                ).catch(error => {

                    resolve({ success: false, msg: error.response.data.error.message })
                });


            })
        },
        editCurrentUser({ commit, getters }, obj) {
            return new Promise((resolve, reject) => {
                HTTP.post("services/app/User/EditUser", obj, {
                    headers: {
                        Authorization: `Bearer ${getters.getterToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(
                    response => {
                        resolve({ success: true, msg: 'Cadastro editado com sucesso!' })
                    }
                ).catch(error => {

                    resolve({ success: false, msg: error.response.data.error.message })
                });
            })
        },
        deleteUser({ getters }, id) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/services/app/User/Delete?Id=${id}`, {
                    headers: {
                        Authorization: `Bearer ${getters.getterToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(
                    response => {
                        resolve({ success: true, msg: 'Usuário deletado!' })
                    }
                ).catch(error => {

                    reject({ success: false, msg: error.response.data.error.message })
                });
            })
        }

    },

    getters: {


    }
}
