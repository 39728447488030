import Vue from "vue";
export default Vue.prototype.convertArray = function(response, names) {
  var arr = [];
  arr.push(
    {
      name: "Receita Bruta",
      total: response.grossProfit.total,
      meta: [
        {
          name: `${names} anterior`,
          porcentagem: response.grossProfit.percentageAboutLastmonth,
          diferenca: response.grossProfit.diferenceAboutLastmonth
        },
        {
          name: "meta",
          porcentagem: response.grossProfit.goalpercentage,
          diferenca: response.grossProfit.goalDiference
        }
      ]
    },
    {
      name: "Receita Liquida",
      total: response.netProfit.total,
      meta: [
        {
          name: `${names} anterior`,
          porcentagem: response.netProfit.percentageAboutLastmonth,
          diferenca: response.netProfit.diferenceAboutLastmonth
        },
        {
          name: "meta",
          porcentagem: response.netProfit.goalpercentage,
          diferenca: response.netProfit.goalDiference
        }
      ]
    },
    {
      name: "Despesas",
      total: response.expense.total,
      meta: [
        {
          name: `${names} anterior`,
          porcentagem: response.expense.percentageAboutLastmonth,
          diferenca: response.expense.diferenceAboutLastmonth
        },
        {
          name: "meta",
          porcentagem: response.expense.goalpercentage,
          diferenca: response.expense.goalDiference
        }
      ]
    },
    {
      name: "Média/Dia",
      total: response.dailyProfit.value,
      lastName: response.dailyProfit.lastName,
      percentageAboutLast: response.dailyProfit.percentageAboutLast
    },
    {
      name: "Margem Líquida",
      total: response.netMargin.value,
      lastName: response.netMargin.lastName,
      percentageAboutLast: response.netMargin.percentageAboutLast
    },
    { name: "Ticket Médio", total: response.ticketAverage }
  );
  return arr;
};
