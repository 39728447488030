<template>
  <div class="search_dp_mobile">
    <div class="bg-danger h-100"> 

    </div>
    <transition-group
      name="staggered-fade"
      tag="ul"
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <li
        v-for="(item, index) in servicosMobile"
        :key="index + 1"
        class="lista_servico_mobile d-flex align-items-center"
        :data-index="index"
      >
        <!-- <router-link :to="`/servico/${item.id}`" class="my-0 pl-3 d-flex justify-content-between classLink">
					<div>
						<strong>{{item.serviceName}} |</strong>
						{{item.categoryName}}
					</div>

					<span>
						 {{item.value | currency }}
					</span>
				</router-link> -->
        <a
          @click="redirectRouterService(item)"
          class="my-0 pl-3 d-flex justify-content-between classLink"
        >
          <div>
            <strong>{{ item.serviceName }} |</strong>
            {{ item.categoryName }}
          </div>

          <span>
            {{ item.value | currency }}
          </span>
        </a>
      </li>
      <li
        v-if="$store.getters.getUser.profile != 1"
        class="solicitar d-flex align-items-center"
        :data-index="servicosMobile.length + 1"
        :key="servicosMobile.length + 1"
        @click.prevent="openModalMobile()"
      >
        <a href="#" class="my-0 pl-3">
          <strong>Solicitar |</strong>
          novo serviço
        </a>
      </li>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: ["servicosMobile"],
  computed: {
    user() {
      return this.$store.getters.getUser.profile;
    }
  },
  methods: {
    redirectRouterService({ id }) {
      if (this.user !== 3) {
        this.toast(
          "Para Realizar algum serviço acessa o perfil vendedor!",
          this.$toast
        );
        return;
      }
      this.$router.push(`/servico/${id}`);
    },
    beforeEnter: function(el) {
      el.style.opacity = 0;
      el.style.height = 0;
    },
    enter: function(el, done) {
      var delay = el.dataset.index;

      setTimeout(function() {
        $(el).velocity({ opacity: 1, height: "50px" }, { complete: done });
      }, delay);
    },
    leave: function(el, done) {
      var delay = el.dataset.index;
      setTimeout(function() {
        $(el).velocity({ opacity: 0, height: 0 }, { complete: done });
      }, delay);
    },
    openModalMobile() {
      this.$emit("closeMenuFront")
      $(".dropdown-menu").fadeOut(500);
      $(".sub_menu").removeAttr("style");
      $(".search_dp_mobile").fadeOut(500);
      $("header").css({ "z-index": "3" });
      $(".input_search_list").removeAttr("style");
      $(".navegation_item.active_item").removeClass("active_item");
      $("#app").removeClass("overlay_custom");
      $("#modalSolicitarServico").modal("show");
    }
  },
};
</script>

<style scoped>

ul {
  padding: 0;
  overflow: auto;
  max-height: 500px;
}

.classLink {
  flex: 1;
  cursor: pointer;
  padding-right: 5px;
}
.lista_servico_mobile {
  background-color: #2474b2;
  height: 50px;
  border-color: #1c5f93;
  border-style: solid;
  border-width: 0px 2px 2px 2px;
}
.lista_servico_mobile a {
  color: #ffffff;
}
.solicitar {
  background-color: #f38235;
  height: 50px;
  border-color: #f38235;
  border-style: solid;
  border-width: 0px 2px 2px 2px;
}
.solicitar a {
  color: #ffffff;
}
.search_dp_mobile {
  position: absolute;
  display: none;
  width: 100%;
  left: 0;

  padding-left: 15px;

  padding-right: 15px;
}
</style>
