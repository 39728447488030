import HTTP from "@/api_system";
export default {
    state: {

    },
    mutations: {

    },
  actions: {
    async getCity({ commit, getters }, params) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/City/GetByUF?nameUf=${params.nameUf}`, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        }).then(response => {
          var result = response.data.result;
           resolve(result);
        }).catch(error => {
            reject({ erro: true, msg: error });
          })
      })
    },
    getCityForUf({ getters }) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/City/GetGroupByUF`, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        }).then(response => {
          var result = response.data.result;
           resolve(result);
        }).catch(error => {
            reject({ erro: true, msg: error });
          })
      })
    },
    },
    getters: {

    }
}
