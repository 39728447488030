export default{
    state: {
        setModal:{
            modalAddService: ""
        },
        
    },
    mutations: {
        openModalAddService(state, payload){
            state.setModal.modalAddService = payload.situation
        },
    },
    getters: {}
}