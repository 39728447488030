<template>
    <div class="menuMobile">
        <nav id="navigationMenu">
            <div class="navHeader">
                <div class="contentHeaderCont">
                    <div class="logoNavCont">
                        <img
                        :src="getImg(user.photo)"
                        class="logoNav"
                        @error="errorImage"
                        />
                    </div>
                    <div class="div_title">
                        <p class="profileUser" style="margin-top: 16px">{{ getProfile(user.profile) }} {{ user.profile != 1 ? "|" : "" }}</p>
                        <p class="nameUser"><strong>
                            <i
                                v-if="user.profile === 3 && homologa"
                                class="fas fa-circle"
                                :style="`color: ${$store.getters.getStatusPinPad}`"
                                ></i>
                                {{ user.displayName }}
                            </strong></p>
                            <p style="margin-bottom: 9px">
                                <strong v-show="user.profile != 1"
                                >{{ around($store.state.login.userScore) }}% da meta</strong
                                >
                            </p>
                            <div class="unidCont">
                                <p v-show="user.profile != 1">Unidade</p>
                                <p class="unity" v-show="user.profile != 1">
                                <strong>  {{$store.getters.tenantRequire.tenancyName }}</strong>
                                </p>
                            </div>
                        </div>
                    <img  @click="closePerfil" src="@/assets/image/iconesMobile/closeMobileProfile.png" class="removeMenu">
                </div>
            </div>
            <div class="navBody">
                <div class="buttonsCont">
                    <div class="LinkImgCont" @click="closePerfil">
                        <router-link class="buttonProfile" to="/perfil">Meu Perfil</router-link>
                        <img src="@/assets/image/iconesMobile/arrowOrange.svg" class="arrowMenu">
                    </div>
                    <div class="LinkImgCont" @click="closePerfil" v-if="($store.state.login.userLogin.profile != 3)">
                        <router-link class="buttonProfile" to="/administrativo">Administrativo</router-link>
                        <img src="@/assets/image/iconesMobile/arrowOrange.svg" class="arrowMenu">
                    </div>
                    <div v-if="$store.state.login.userLogin.profile !=1" class="LinkImgCont" @click="closePerfil">
                        <router-link
                        class="buttonProfile"
                        :to="'/metas'"
                        exact-active-class="active"
                        
                        >
                        Minhas Metas
                        </router-link>
                        <img src="@/assets/image/iconesMobile/arrowOrange.svg" class="arrowMenu">
                    </div>
                    <div class="LinkImgCont" @click="closePerfilExit">
                        <a class="buttonProfile" id="logout" >Sair</a>
                        <img src="@/assets/image/iconesMobile/arrowOrange.svg" class="arrowMenu">
                    </div>                  
                </div>
            </div>
            <div class="footerButtons">
                    
            </div>
        </nav>
        <div @click="closePerfil" class="backdropCont"></div>
        <modalPA></modalPA>
    </div>      
</template>

<script>
import modalPA from '../../caixacp/modal_pedidos_abertos.vue'
import HTTP from "@/api_system"
export default {
    props:['configOpen'],
    components:{
        modalPA
    },
    data(){
        return{
            homologa: false,
        }
    },
    methods:{
        openPerfil(){
            const menu = document.querySelector('#navigationMenu')
            const backdrop = document.querySelector('.backdropCont')
            backdrop.classList.add('open')
            menu.classList.add('open')
        },
        closePerfil(){
            this.$emit("turnFalsePerfil")
            const menu = document.querySelector('#navigationMenu')
            const backdrop = document.querySelector('.backdropCont')
            backdrop.classList.remove('open')
            menu.classList.remove('open')
        },
        closePerfilExit(){
                if(this.user.profile == 3) {
                    HTTP.get('services/app/Order/GetCountUserPendingOrderDay', {
                    headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
                    })
                    .then(response => {
                    console.log(response)
                    if(response.data.result > 0){
                        this.$emit("turnFalsePerfil")
                        const menu = document.querySelector('#navigationMenu')
                        const backdrop = document.querySelector('.backdropCont')
                        backdrop.classList.remove('open')
                        menu.classList.remove('open')
                        $("header").css({ "z-index": "3" });
                        $(".user_dp").fadeOut(500);
                        $("#app").removeClass("overlay_custom");
                        this.OpenModal()
                    }
                    else{
                        this.$emit("turnFalsePerfil")
                        const menu = document.querySelector('#navigationMenu')
                        const backdrop = document.querySelector('.backdropCont')
                        backdrop.classList.remove('open')
                        menu.classList.remove('open')
                        this.$router.push('/logout')
                    }
                    
                    })
                    .catch(err => console.log(err)) 
                }else{
                    this.$emit("turnFalsePerfil")
                    const menu = document.querySelector('#navigationMenu')
                    const backdrop = document.querySelector('.backdropCont')
                    backdrop.classList.remove('open')
                    menu.classList.remove('open')
                    this.$router.push('/logout')
                }
            
        },
        OpenModal(){
            $("#app").removeClass("overlay_custom");
            $('#modal_pedidos_abertos').appendTo("body").modal('show');
        },
        homologaShow() {
				let urlApi = process.env.VUE_APP_ROOT_API;

				if (urlApi === "//apiteste.pontofacil.fasters.com.br") {
					this.homologa = true;
				}
		},
    },
    mounted() {
			this.homologaShow()
		},
    watch:{
        configOpen(x){
            if(x === true){
                this.openPerfil()
            }
        },
        $route(x){
            if(x.path !== this.$router.push){
                this.closePerfil()
            }
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
    }
}
</script>

<style scoped>
.LinkImgCont{
    display: flex;
    flex-direction: row;
    border-bottom: 1px rgb(200, 198, 198) solid;
    width: 100%;
}
.LinkImgCont a {
    min-width: 80%;
}
.buttonProfile{
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: gray;
    font-size: 18px;
}
#logout{
    color: red;
}
.buttonsCont{
    width: 90%;
    margin-top: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.contentHeaderCont{
    display: flex;
    width: 100%;
    flex-direction: row;
}
p{
    color: #FFFF;
    font-size: 10px!important;
    margin-bottom: 5px!important;
}
.profileUser{
    margin-top: 8px!important;
}
.nameUser{
    font-size: 12px!important;
}

.unidCont{
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  border-top: 1px #FFFF solid;
}
.unidCont strong{
    margin-left: 2px;
}
.backdropCont{
    background-color: gray;
    z-index: 9;
    min-height: 100vh;
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: .2s ease-in-out;
    opacity: 0.6!important;
}

.logoNavCont .logoNav {
  object-fit: cover;
  height: auto;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
.logoNavCont {
    border: 4px solid #f69d61!important;
    margin-left: 8%;
    margin-top: 8px;
    margin-right: 6px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    overflow: hidden;
}
.logoNav{
    width: 50%;
}
.removeMenu{
    position: absolute;
    top: 1.7%;
    right: 4%;
    width: 10%;
}
.navHeader{
    background-color: #F38235;
    padding-top: 3%;
    padding-bottom: 3%;
}
#navigationMenu{
    z-index: 99999999999;
    min-height: 100vh;
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    width: 70%;
    transition: .2s ease-in-out;
    background-color: #FFF;
}
a{
    margin-bottom: 3%!important;
    text-decoration: none;
}
.open{
    left: 0!important;
}
.removeMenu{
    cursor: pointer;
}
@media screen and (min-width:768px){
    .menuMobile{
        display: none;
    }
}

</style>