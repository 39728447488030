import axios from 'axios'

const HTTP =
    axios.create({
        baseURL: process.env.VUE_APP_ROOT_API + "/api/",

        responseType: "json",

        headers: {

            // Authorization: `Bearer ${store.getters.getterToken}`
        },

    })
export default HTTP
