<template>
    <modal
      modalId="modalInstrucoes"
      :load="false"
      :typeLg="`modal_md`"
      aria-hidden="true"
    >
      <transition-group tag="div">
        <instrucao
          :instructions.sync="superObj.instructions"
          key="01"
        />

        <div
          v-for="item in superObj.instructions"
          :key="item.sequence"
          :item="item"
          :instructions="superObj.instructions"
          :link="superObj.link"
        >
        </div>
      </transition-group>
      <br>
      <div class="row m-auto col-md-12">
        <div class="col-md-12 ml-auto">
          <div class="row">
            <div class="col-md-5 m-auto">
              <button
                type="button"
                class="btn btn_secondary"
                @click.prevent="$emit('closeModalInstrucoes')"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </template>
  
  <script>
  import instrucao from "../_Instrucao";
  export default {
    props: ['superObj'],
    components: {
      modal: () => import("@/components/modal/modalDefault.vue"),
      instrucao
    },
  };
  </script>
  
  <style scoped src="@/assets/style/modal.css"></style>
  