<template>
  <div
    class="modal fade"
    tabindex="-1"
    id="modalIframe"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <iframe
          width="100%"
          height="315"
          :src="`https://www.youtube.com/embed/${link}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          autoplay
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["link"],
  data() {
    return {};
  },
  mounted() {
    var self = this;
    $("#modalIframe").on("hidden.bs.modal", function(e) {
      self.$emit("limpaIframe");
    });
  }
};
</script>

<style>
</style>