<template>
  <div class="d-flex align-items-center user">
    <div class="position-relative d-flex align-items-center h-100 w-100">
      <div class="position-absolute" v-if="user">
        <div @click="openPerfilMobile" class="position-relative">
          <div class="image_div">
            <img
              :src="getImg(user.photo)"
              class="img_user"
              @error="errorImage"
            />
          </div>
          <div
            class="position-absolute cargo d-flex align-items-center justify-content-center"
            v-if="user.profile != 1"
          >
            <span>{{ tenantSigla }}</span>
          </div>
        </div>
      </div>
      <div
        class="bg h-100 d-flex align-items-center"
        id="titleContContainer"
        @click.prevent="dropdownShow($event)"
        @mouseover="animateEnter()"
        @mouseleave="animateExit()"
      >
      <div class="titleCont">
        <div class="div_title">
          <p>
            {{ getProfile(user.profile) }}
          </p>
          <h4>
            <strong>{{ user.displayName }}</strong>
          </h4>
          <p v-show="user.profile != 1"> 
              {{ around($store.state.login.userScore) }}% da meta
          </p>
        </div>
        <div class="div_subTitle">
          <p v-show="user.profile != 1">Unidade</p>
          <p v-show="user.profile != 1">
            <strong>{{ $store.getters.tenantRequire.tenancyName }}</strong>
          </p>
        </div>
      </div>
      </div>  
      <div
        class="position-absolute refresh d-flex align-items-center"
        @click.prevent="!notClick ? test() : ''"
        id="iconCont"
      >
        <i class="fas fa-sync-alt"></i>
      </div>
      <div class="position-absolute engr d-flex align-items-center"
      id="iconCont">
        <icons_custom
          icone="engrenagem"
          bg="#ffffff"
          wi="15px"
          hei="15px"
          style="position: inherit;"
        />
      </div>
    </div>
    <dropdown />
    <perfilUser :configOpen="configOpen" @turnFalsePerfil="turnFalsePerfil"></perfilUser>
  </div>
</template>

<script>
import perfilUser from "@/components/utils/navMenu/perfilUserMobile.vue"
import icons_custom from "@/components/utils/_Icon";
import dropdown from "./user/_Dropdown_cp";
export default {
  components: {
    icons_custom,
    dropdown,
    perfilUser 
  },
  data() {
    return {
      totalCalc: 0,
      notClick: false,
      homologa: false,
      configOpen: false
    };
  },
  	mounted() {
			this.homologaShow()
		},
  methods: {
     homologaShow() {
				let urlApi = process.env.VUE_APP_ROOT_API;

				if (urlApi === "//apiteste.pontofacil.fasters.com.br") {
					this.homologa = true;
				}
		},
    dropdownShow(e) {
      this.check();
      if ($(".user_dp").css("display") == "block") {
        this.animateExit();
        $("header").css({ "z-index": "3" });
        $(".user_dp").fadeOut(500);
        $("#app").removeClass("overlay_custom");
      } else {
        $("header").css({ "z-index": "1031" });
        $(".user_dp").fadeIn(500);
        $("#app").addClass("overlay_custom");
      }
    },
    animateEnter() {
      TweenMax.to(".engr", 1, {
        rotation: 360,
        ease: Sine.easeInOut
      });
    },
    animateExit() {
      TweenMax.to(".engr", 1, {
        rotation: -360,
        ease: Sine.easeInOut
      });
    },
    check() {
      if ($(".sub_menu_dp").css("display") == "flex") {
        $(".sub_menu_dp").remove("active_item");
        $(".sub_menu_dp").fadeOut(500);
        $(".sub_menu").removeAttr("style");
      }
    },
    test() {
      TweenMax.to(".refresh", 1, {
        rotation: 360,
        ease: Sine.easeInOut
      });
      this.notClick = true;
      this.$store.dispatch("getServices").then(resolve => {
        setTimeout(() => {
          TweenMax.to(".refresh", 1, {
            rotation: 0,
            ease: Sine.easeInOut
          });
          this.notClick = false;
        }, 2000);
      });
    },
    openPerfilMobile(){
      this.configOpen = true
    },
    turnFalsePerfil(){
      this.configOpen = false
    }
  },
  watch: {
    "$store.state.login.userScore": function() {
      this.totalCalc = 0;
    }
    // "$store.state.statusPinPad": function() {
    //   console.log("oi eu");
    //   console.log(this.$store.state.statusPinPad);
    // }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    totalMetas() {
      setInterval(() => {
        if (this.totalCalc < parseInt(this.$store.state.login.userScore)) {
          this.totalCalc++;
        }
      }, 50);
      return this.totalCalc;
    },
    tenantSigla() {
      return this.$store.getters.tenantSigla;
    }
  }
};
</script>

<style scoped>
h4 {
  font-size: 13px;
  color: #ffffff;
  margin-bottom: 0;
}
.titleCont p {
  font-size: 9px;
  color: #ffffff;
  margin-bottom: 0;
}
.titleCont {
  margin-left: 38px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.div_title{
  margin-top: 7%;
  margin-bottom: 4%;
}
.div_subTitle{
  padding-top: 2%;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top: 0.5px rgb(223, 223, 223) solid;
}
.div_subTitle p{
  font-size: 9px;
  color: #ffffff;
}
.bg {
  background-color: #f4ab7b;
  width: calc(100% - 30px);
  margin-left: 30px;
  cursor: pointer;
  /* transition: background 1s linear; */
}
.user {
  height: calc(100% + 1px);
  width: 100%;
}

.image_div {
  border-radius: 50%;
  border: 3px solid #F37018;
  height: 60px;
  width: 60px;
  overflow: hidden;
}
.image_div .img_user {
  object-fit: cover;
  height: auto;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
.cargo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #2474b2;

  left: -10px;
  bottom: 0;
  border: 2px solid#fff;
}
.cargo span {
  font-weight: 500;
  color: #fff;
  font-size: 10px;
}
.refresh {
  cursor: pointer;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  font-size: 13px;
  color: #ffffff;
  justify-content: space-evenly;
  transition: all 0.2s linear;
}

.engr {
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  justify-content: space-evenly;
}
.engr .mask {
  stroke: #fff;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
}
.fa-circle {
  font-size: 10px;
}
#titleContContainer{
  height: 100%!important;
  background-color: #F37018;
}
@media screen and (max-width:767px){
  #titleContContainer{
    display: none!important;
  }
  #iconCont{
    display: none!important;
  }
  .user{
    height: calc(100% + 1px);
    width: 10%;
    position: absolute!important;
    right: 11%!important;
  }
}
</style>
