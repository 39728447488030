import HTTP from '@/api_system'
export default {
  state: {
    itemRequest: []
  },
  mutations: {
    setItemRequest (state, newValue) {
      return state.itemRequest = newValue
    }
  },
  actions: {
    requestServiceBB ({ getters }) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/Order/GetUserPendingOrderDayBB?userId=${getters.getIdAllUser[1]}`, { headers: { Authorization: `bearer ${getters.getterToken}` } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    finishServiceBB ({ getters }, orderId) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/Order/CompleteOrderBB?orderId=${orderId}`, {}, { headers: { Authorization: `bearer ${getters.getterToken}` } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    downloadService ({ getters }, path) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/OrderServiceFile/GetFileLanding?fullPath=${path}`, { headers: { Authorization: `bearer ${getters.getterToken}` } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  getters: {
    getItemRequest (state) {
      return state.itemRequest
    }
  }
}
