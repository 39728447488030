import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import constantes from './const/const'
import Notifications from 'vue-notification'
import validate from './const/validate'
import VueCurrencyFilter from "vue-currency-filter";
import Multiselect from 'vue-multiselect';
import vue2Toast from 'vue2-toast';
import VueSvgGauge from 'vue-svg-gauge'
import money from 'v-money'
import VueCarousel from 'vue-carousel';
import Toast from "vue-toastification";
import InputTag from 'vue-input-tag'
import { Datetime } from 'vue-datetime'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)


import "vue-multiselect/dist/vue-multiselect.min.css";
import 'vue2-toast/lib/toast.css';
import './registerServiceWorker'
import 'bootstrap-icons/font/bootstrap-icons';
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false
Vue.use(Datetime)
Vue.use(VueCarousel);
Vue.use(vue2Toast);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 2,
  newestOnTop: true
});
Vue.use(money)
Vue.use(Notifications)
Vue.use(VueCurrencyFilter, {
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true
});
Vue.use(Toast);
Vue.use(VueSvgGauge)
Vue.component('multiselect', Multiselect)
Vue.component('input-tag', InputTag)
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')



