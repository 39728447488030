<template>
  <div class="allCont">
    <header class="position-relative" style="z-index: 3">
      <transition name="vertical">
        <div class="alert_important" v-if="$store.getters.urgentAlert">
          <div class="container h-100">
            <div class="col-md-12 h-100">
              <div
                class="row h-100 align-items-center"
                style="min-height: 50px"
              >
                <div class="col-md-2 text-center">
                  <i class="fas fa-exclamation-triangle"></i>
                </div>
                <div class="col-md-8 text-center">
                  <p class="mb-0">
                    {{ $store.getters.urgentAlert.description }}
                  </p>
                </div>
                <div class="col-md-2 text-center">
                  <i class="fas fa-exclamation-triangle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <nav class="container-fluid">
        <div class="row align-items-center h-100">
          <div class="col-md-2 text-center">
            <router-link :to="'/'">
              <img src="@/assets/image/Logo3-108.png" class="logo_header" />
            </router-link>
          </div>
          <div @click="abre" class="openMenuCont">
            <img
              src="@/assets/image/iconesMobile/openMenu.png"
              class="openMenuImg"
            />
          </div>
          <div @click="openSearch" class="searchServiceCont">
            <img
              src="@/assets/image/iconesMobile/searchService.svg"
              class="searchServiceImg"
            />
          </div>
          <div class="inputAndLinksCont">
            <div class="row" id="linksCont">
              <router-link
                :to="'/'"
                exact-active-class="active"
                :class="$store.getters.getUser.profile == 1 ? ' mx-2' : ' mx-2'"
                class="d-flex align-items-center justify-content-center"
                >Home</router-link
              >
              <router-link
                :to="'/mural'"
                exact-active-class="active"
                :class="$store.getters.getUser.profile == 1 ? ' mx-2' : ' mx-2'"
                class="d-flex align-items-center justify-content-center"
                >Mural</router-link
              >
              <!-- <router-link
                  :to="'/notificacoes'"
                  exact-active-class="active"
                  :class="$store.getters.getUser.profile == 1 ? ' mx-2' : ' mx-2'"
                  class="d-flex align-items-center justify-content-center position-relative"
                  title="Notificações"
                >Notificações<div
                    v-if="
                      notificacaoLength && $store.getters.getUser.profile != 1
                    "
                    class="position-absolute notification d-flex align-items-center justify-content-center"
                  >
                     <span>{{ notificationUser }}</span>
                     <span>{{ notificacaoLength }}</span>
                  </div>
                </router-link> -->

              <router-link
                :to="'/dados'"
                exact-active-class="active"
                v-if="$store.getters.getUser.profile !== 3"
                class="mx-2 d-flex align-items-center justify-content-center position-relative"
                >Relatórios</router-link
              >

              <router-link
                :to="'/arquivos'"
                exact-active-class="active"
                class="mx-2 d-flex align-items-center justify-content-center position-relative"
                >Arquivos e Treinamentos</router-link
              >

              <router-link
                :to="'/solicitacao'"
                exact-active-class="active"
                v-if="$store.getters.getUser.profile == 1"
                class="mx-2 d-flex align-items-center justify-content-center position-relative"
                >Solicitações
                <div
                  v-if="
                    $store.getters.totalSolicita &&
                      ($store.getters.totalSolicita.serviceEditLenght ||
                        $store.getters.totalSolicita.servicesLenght)
                  "
                  class="position-absolute notificationSolici d-flex align-items-center justify-content-center"
                >
                  <span>{{
                    $store.getters.totalSolicita.serviceEditLenght +
                      $store.getters.totalSolicita.servicesLenght
                  }}</span>
                </div>
              </router-link>

              <router-link
                :to="'/banners'"
                exact-active-class="active"
                v-if="$store.getters.getUser.profile == 1"
                class=" mx-2 d-flex align-items-center justify-content-center position-relative"
                >Recados</router-link
              >
              <div
                class="hmlButton"
                v-if="homologa === true"
                style="display:flex;"
              >
                <router-link
                  :to="'/auditoria'"
                  exact-active-class="active"
                  v-if="$store.getters.getUser.profile == 1"
                  class=" mx-2 d-flex align-items-center justify-content-center position-relative "
                  title="Auditoria"
                  >Auditoria</router-link
                >
                <router-link
                  :to="'/data'"
                  exact-active-class="active"
                  v-if="$store.getters.getUser.profile == 1"
                  class=" mx-2 d-flex align-items-center justify-content-center position-relative"
                  title="Data"
                  >Data</router-link
                >
                <!-- <button
                    @click="swagger"
                    exact-active-class="active"
                    v-if="$store.getters.getUser.profile == 1"
                    class=" mx-2 d-flex align-items-center justify-content-center position-relative"
                    title="Swagger"
                  >Swagger</button> -->
              </div>
            </div>
            <div
              class="col-md-6 input_search_list"
              :class="activeClassInput"
              id="inputSearchServiceMobile"
            >
              <input
                v-on:blur="funcToUnblur"
                type="search"
                class="header_input_search pl-3"
                v-model="search"
                placeholder="Digite o nome de algum serviço…"
                autocomplete="off"
              />
              <pesquisa :servicos="servicos" @showModalDegustacaoHeader="showModalDegustacaoHeader"/>
            </div>
          </div>
          <div class="supAndNotCont">
            <router-link to="/suporte/2" class="iconCont" title="Suporte">
              <font-awesome-icon icon="fas-light fa-headset" class="supIcon" />
            </router-link>
            <router-link
              to="/notificacoes"
              class="iconCont"
              title="Notificações"
            >
              <font-awesome-icon icon="fas-light fa-bell" class="notIcon" />
            </router-link>
            <div
              v-if="notificacaoLength && $store.getters.getUser.profile != 1"
              class="position-absolute notification d-flex align-items-center justify-content-center"
            >
              <span>{{ notificacaoLength }}</span>
            </div>
          </div>
          <user class="col-md-2 pr-0" />
        </div>
      </nav>
    </header>
    <subMenu class="position-relative" ref="subMenu" @showModalDegustacao="showModalDegustacao" @showModalDegustacaoDropdownServico="showModalDegustacaoDropdownServico"/>
    <menuMobile :defineTrue="defineTrue" @turnFalse="turnFalse"></menuMobile>
    <searchService
      :configOpenSearch="configOpenSearch"
      @turnFalseSearch="turnFalseSearch"
    ></searchService>
    <modal v-if="$store.getters.getUser.profile != 1" />
    <modalDegustacaoDropdownHeader/>
    <modalDegustacaoDropdownServico/>
    <modalDegustacaoHeader/>
  </div>
</template>

<script>
import searchService from "@/components/utils/navMenu/searchServiceMobile.vue";
import menuMobile from "@/components/utils/navMenu/menuMobile.vue";
import user from "./_User_data";
import subMenu from "./_Sub_header";
import pesquisa from "./_dp_pesquisa";
import icons_custom from "@/components/utils/_Icon";
import modal from "@/components/utils/solicitar_servico";
import modalDegustacaoHeader from "@/components/modal/modalDegustacaoHeader";
import modalDegustacaoDropdownHeader from "@/components/modal/modalDegustacaoDropdownHeader";
import modalDegustacaoDropdownServico from "@/components/modal/modalDegustacaoDropdownServico";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    user,
    subMenu,
    icons_custom,
    pesquisa,
    modal,
    menuMobile,
    searchService,
    modalDegustacaoDropdownHeader,
    modalDegustacaoDropdownServico,
    modalDegustacaoHeader
    
  },
  data() {
    return {
      activeClassInput: "",
      search: "",
      homologa: false,
      notificationUser: null,
      defineTrue: false,
      configOpenSearch: false
    };
  },
  created() {},
  mounted() {
    var self = this;
    $("#app").click(function(e) {
      if ($(e.target).is(".overlay_custom")) {
        self.activeClassInput = "";
        $(".dropdown-menu").fadeOut(500);
        $(".sub_menu").removeAttr("style");
        $(".search_dp").fadeOut(500);
        $("header").css({ "z-index": "3" });
        $(".input_search_list").removeAttr("style");
        $(".navegation_item.active_item").removeClass("active_item");
        $("#app").removeClass("overlay_custom");
      }
    });
    $(".input_search_list").click(function(e) {
      e.target.focus();
      $(".dropdown-menu").fadeOut(200);
      $(".sub_menu").removeAttr("style");
      $(".search_dp").fadeOut(200);
      if ($(".search_dp").css("display") != "block") {
        self.activeClassInput = "active_class_input";
        $("#app").addClass("overlay_custom");
        $("header").removeAttr("style");
        $(".search_dp").fadeIn(700);
      } else {
        self.activeClassInput = "";
        $("#app").removeClass("overlay_custom");
        $(".search_dp").fadeOut(500);
        $("header").css({ "z-index": "3" });
      }
    });
    if (this.$store.getters.getUser.profile != 1) {
      this.getNotificationStatus();
    }
    this.initSignal();
    this.initSignalJob();
    if (this.$store.getters.getUser.profile == 1) {
      this.headerHomologa();
    }
  },
  methods: {
    ...mapActions(["getNotificationStatus", "initSignal", "initSignalJob", "requestServiceBB"]),
    searchCategoria() {},
    swagger() {
      axios
        .get(process.env.VUE_APP_ROOT_API, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
        .then(result => {
          console.log(result);
          window.open(
            URL,
            "janela1",
            `
            width=800,
            height=600,
            directories=no,
            location=no,
            menubar=no,
            scrollbars=no,
            status=no,
            toolbar=no,
            resizable=no`
          );

          return;
          // window.open(Html)
        })
        .catch(err => console.log(err));
    },
    headerHomologa() {
      let urlApi = process.env.VUE_APP_ROOT_API;

      if (urlApi === "//apifunc.pontofacil.fasters.com.br") {
        this.homologa = true;
      }
    },
    abre() {
      this.defineTrue = true;
    },
    turnFalse() {
      this.defineTrue = false;
    },
    openSearch() {
      this.configOpenSearch = true;
    },
    turnFalseSearch() {
      this.configOpenSearch = false;
    },
    funcToUnblur() {
      this.activeClassInput = "";
      $("#app").removeClass("overlay_custom");
      $(".search_dp").fadeOut(500);
      $("header").css({ "z-index": "3" });
    },
    showModalDegustacao() {
      $("#app").removeClass("overlay_custom");
      $("#modalDegustacaoDropdownHeader").modal("show");
    },
    showModalDegustacaoHeader() {
      $("#app").removeClass("overlay_custom");
      $("#modalDegustacaoHeader").modal("show");
    },
    showModalDegustacaoDropdownServico() {
      $("#app").removeClass("overlay_custom");
      $("#modalDegustacaoDropdownServico").modal("show");
    }
  },

  computed: {
    ...mapGetters({
      notificationTitle: "getNotificationStatus",
      getItemCardService: "getItemCardService",
      get_message: "get_message",
      getTenantIdCurrentDelete: "getTenantIdCurrentDelete"
    }),
    notificacaoLength() {
      //console.log(this.$store.getters.notifQtd)
      // this.notificationUser = this.$store.getters.notifQtd
      return this.$store.getters.notifQtd;
    },

    servicos() {
      var searchKey = this.search;
      return this.$store.getters.buscaService.filter(item => {
        var itemService = item.serviceName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        var categoryName = item.categoryName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return (
          (searchKey.length > 0 &&
            itemService.toUpperCase().includes(searchKey.toUpperCase())) ||
          (searchKey.length > 0 &&
            categoryName.toUpperCase().includes(searchKey.toUpperCase()))
        );
      });
    }
  },
  watch: {
    get_message: function() {
      if (this.get_message == 1) {
        this.$store.dispatch("getAlert");
        return;
      }

      if (this.get_message == 2) {
        this.$store.dispatch("getSolicitacao");
        return;
      }

      if (this.get_message == 3) {
        this.$store.dispatch("getServices");
        return;
      }

      if (this.get_message == 6) {
        this.notificationUser = this.$store.getters.notifQtd;
        return;
      }

      if (
        this.get_message ==
          `10_${this.$store.getters.tenantRequire.tenantId}` &&
        this.$store.getters.getUser.profile != 1
      ) {
        this.getNotificationStatus();
      }

      if (
        this.get_message ==
          `12_${this.$store.getters.tenantRequire.tenantId}` &&
        this.$store.getters.getUser.profile === 3
      ) {
        this.requestServiceBB();
      }
      //console.log("not parameters", this.get_message);
    }
  }
};
</script>

<style scoped>
#linksCont {
  margin-bottom: 7px;
  padding-bottom: 5px;
  margin-top: 10px;
  width: 97%;
  justify-content: flex-start;
  border-bottom: 1px #98c4e6 solid;
}
#linksCont a {
  text-decoration: none;
  color: #a5a6ad;
  padding-right: 20px;
  padding-left: 2px;
  margin-left: 0 !important;
}
#linksCont a:hover {
  color: #2474b2;
  transition: 0.4s;
}
#inputSearchServiceMobile {
  max-width: 100% !important;
  width: 100% !important;
}
.inputAndLinksCont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
}
.supAndNotCont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 6.6%;
  height: 73%;
  margin-top: 0.6%;
}
.supIcon {
  width: 100%;
  height: 100%;
  color: #938e8e;
}
.notIcon {
  width: 100%;
  height: 100%;
  color: #938e8e;
}
.supIcon:hover {
  color: black;
  transition: 0.6s;
}
.notIcon:hover {
  color: black;
  transition: 0.6s;
}
.fa-exclamation-triangle {
  color: #ffffff;
}
.alert_important {
  background-color: #e67979 !important;
  min-height: 50px;
}
.alert_important p {
  color: #ffffff;
}
.active_class_input {
  z-index: 1031;
}
.active_class_input input {
  border: 2px #f38235 solid;
  border-radius: 0;
  background-color: #fff;
}
/* header {
  z-index: 1031;
} */

nav {
  height: 100px;
  background-color: #fefefe;
}
.logo_header {
  height: 70px;
  width: auto;
}

.header_input_search {
  background-image: url("../../assets/image/icones/orangeSearchIcon.svg") !important;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #b2b2b2;
  background: #ededed;
  font-weight: 500;
  border-radius: 7px;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.73em + 0.373rem) calc(0.73em + 0.373rem);
  height: 40px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: none;
  background-color: #eef5fa;
  margin-top: 8px;
  margin-bottom: 4px;
}
.header_input_search::placeholder {
  color: #b2b2b2;
}

.notification {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #f38235;
  right: 17.8%;
  border: 2px solid#fff;
}
.notification span {
  font-weight: 500;
  color: #fff;
  font-size: 8px;
}
.notificationSolici {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #f38235;
  right: 8.8%;
  border: 2px solid#fff;
  bottom: 79%;
}
.notificationSolici span {
  font-weight: 500;
  color: #fff;
  font-size: 8px;
}
.searchServiceCont {
  position: absolute;
  right: 22%;
  cursor: pointer;
}
.hmlButton button {
  border: none;
  outline: none;
}
.openMenuCont {
  width: 10%;
  position: absolute;
  left: 3%;
  cursor: pointer;
}
.iconCont {
  width: 34%;
  height: 30%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.openMenuImg {
  width: 100%;
}
@media screen and (max-width: 1850px) {
  .notification {
    right: 17.5% !important;
  }
}
@media screen and (max-width: 1350px) {
  .notification {
    right: 17.2% !important;
  }
}
@media screen and (max-width: 1200px) {
  .notIcon {
    margin-left: 10px;
  }
  .notification {
    right: 16.4% !important;
  }
}
@media screen and (max-width: 950px) {
  .logo_header {
    height: 53px !important;
  }
  .notification {
    right: 15.9% !important;
  }
}
@media screen and (min-width: 768px) {
  .searchServiceCont {
    display: none !important;
  }
  .openMenuCont {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .openMenuCont {
    display: block !important;
  }
  .searchServiceCont {
    display: block !important;
  }
  .inputAndLinksCont {
    display: none !important;
  }
  .logo_header {
    position: absolute !important;
    top: -40px !important;
    left: 18% !important;
  }
  @media screen and (min-width: 550px) {
    .logo_header {
      height: 74px !important;
    }
  }
  @media screen and (max-width: 549px) {
    .logo_header {
      margin-top: 2%;
    }
  }
  @media screen and (min-width: 500px) {
    .openMenuImg {
      width: 73%;
    }
  }
  .supAndNotCont {
    display: none;
  }
}
</style>
