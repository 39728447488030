<template>
	<div>
		<div class="login_cpf">
			<ValidationObserver ref="form">
				<form class="modal_login row" @submit.prevent="loginStep">
					<div class="col-md-12 my-2">
						<label class="label_default mb-0">
							Qual seu
							<strong>CPF ?</strong>
						</label>
					</div>

					<ValidationProvider
						v-slot="{ errors, ariaMsg, classes ,validate}"
						rules="required|min:11"
						name="CPF"
						tag="div"
						:bails="false"
						class="col-md-12 my-1"
					>
						<the-mask
							type="text"
							id="selectLogin"
							class="input_default form-control"
							mask="###.###.###-##"
							@blur.native="validate($event)"
							v-model="CPF"
							placeholder="000.000.000-00"
							:class="classes"
							:masked="false"
						/>
					</ValidationProvider>
					<div class="col-md-12 my-1">
						<button type="submit" class="btn_default px-2 py-2" :disabled="disabled">
							<span
								class="spinner-border spinner-border-sm"
								role="status"
								aria-hidden="true"
								v-show="disabled"
							></span>
							{{ !disabled ? "Entrar" : "" }}
						</button>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
	import { TheMask } from "vue-the-mask";

	export default {
		components: { TheMask },

		data() {
			return {
				disabled: false,
				CPF: ""
			};
		},
		mounted() {
			// const host = window.location.host.split(".");
			// if (host.length == 5) {
			// 	this.getTenant(host[0]);
			// }
		},
		methods: {
			loginStep() {
				this.$refs.form.validate().then(success => {
					if (success) {
						this.disabled = true;
						this.$store.dispatch("cpfForLogin", this.CPF).then(resolve => {
							if (!resolve.erro) {
								this.disabled = false;
								this.$parent.gratientAnimator(
									"linear-gradient(135deg, #e0a638 0%, #f38235 100%)"
								);
								this.$router.push("/senha");
							} else {
								this.$notify({
									group: "erros",
									type: "error",
									text: `<i class="icon ion-close-circled"></i>${resolve.message}`
								});
								this.disabled = false;
							}
						});
					}
				});
			},
		},
		computed: {}
	};
</script>


<style scoped>

</style>
