<template>
    <modal
      modalId="modalDegustacaoDropdownHeader"
      :load="false"
      :typeLg="`modal_md`"
    >
      <label class="label_default label_blue label_lg align-middle title">
        <h5><strong>  DESCUBRA UM NOVO NÍVEL DE SUCESSO!</strong></h5> 
      </label>
      <div class="image">
        <img
          alt="QR Code"
          src="@/assets/image/qrcode_degustacao.png"
        />
      </div>
      <div class="col-md-12 mb-1 label">
        <label class="label_default label_blue label_lg align-middle">
          <strong>Obtenha uma unidade da primeira franquia Fintech <br> do Brasil, com lucro de mais de R$ 12.000 por mês!</strong> 
        </label>
      </div>
      <div class="row m-auto col-md-12">
        <div class="col-md-12 ml-auto">
          <div class="row">
            <div class="col-md-5 m-auto">
              <button
                type="button"
                class="btn btn_secondary"
                @click.prevent="closeModal()"
              >
              Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </template>
  
  <script>
  export default {
    components: {
      modal: () => import("@/components/modal/modalDefault.vue"),
    },
    props: ["modalIdDegustacao"],
    methods: {
      closeModal() {
        $("#app").addClass("overlay_custom");
        $("#modalDegustacaoDropdownHeader").modal("hide");
      }
    }
  };
  </script>
  
  <style scoped src="@/assets/style/modal.css"></style>
  <style scoped>
  .title {
    padding-left: 20px;
    margin-top: -5px;
  }
  .label {
    height: 70px;
    text-align: center;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
  }
  .image{

    display: flex;
    justify-content: center;
  }
  img {
    width: 300px;
    height: 330px;
  }
  </style>
  