<template>
  <div class="dropdown-menu sub_menu_dp_f px-1 py-0" id="dropdownCont">
    <MenuCategoria @hoverMenu="hoverMenu" :servicos="servicosFinanceiros" />
    <MenuConvenio v-if="listaConvenios != ''" @hoverMenu="hoverMenu" :servicos="listaConvenios" />
    <MenuPublico v-if="listaPublicos != ''" @hoverMenu="hoverMenu" :servicos="listaPublicos" />
    <subMenu
      :listaServ.sync="listaServ"
      @showModalDegustacaoDropdownServico="showModalDegustacaoDropdownServico"
    />
  </div>
</template>

<script>
import MenuCategoria from "./_MenuCategoria";
import MenuConvenio from "./_MenuConvenio";
import MenuPublico from "./_MenuPublico";
import subMenu from "./_Sub_categoria_dp";
export default {
  components: {
    subMenu,
    MenuCategoria,
    MenuConvenio,
    MenuPublico
  },
  data() {
    return {
      listaConvenios: [],
      listaPublicos: [],
      listaServ: [],
      servicosFinanceiros: [
        {
          name: "Consignado",
          type: "categoria",
          items: [
            {
              name: "INSS",
              type: "convenio",
              items: [
                {
                  name: "Aposentados e Pensionistas",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Aeronáutica",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Exército",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Marinha",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Municipal",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Estadual",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Federal",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                }               
              ]
            },
            {
              name: "Forças Armadas",
              type: "convenio",
              items: [
                {
                  name: "Aposentados e Pensionistas",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Aeronáutica",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Exército",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refinanciamnto de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Marinha",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Municipal",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Estadual",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Federal",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                }
              ]
            },
            {
              name: "Servidores Públicos",
              type: "convenio",
              items: [
                {
                  name: "Aposentados e Pensionistas",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Aeronáutica",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Exército",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Marinha",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Municipal",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Estadual",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                },
                {
                  name: "Federal",
                  type: "publico",
                  items: [
                    {
                      name: "Refinanciamento",
                      type: "servico"
                    },
                    {
                      name: "Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Refin. de Portabilidade",
                      type: "servico"
                    },
                    {
                      name: "Cartão Consignado",
                      type: "servico"
                    },
                    {
                      name: "Cartão Benefício",
                      type: "servico"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: "Antecipação do FGTS",
          type: "convenio",
          items: [
            {
              name: "BMG",
              type: "servico"
            },
            {
              name: "FACTA",
              type: "servico"
            },
            {
              name: "SABEMI",
              type: "servico"
            },
            {
              name: "ITAÚ",
              type: "servico"
            },
            {
              name: "PAN",
              type: "servico"
            },
            {
              name: "C6 BANK",
              type: "servico"
            },
            {
              name: "DAYCOVAL",
              type: "servico"
            },
            {
              name: "BV",
              type: "servico"
            },
            {
              name: "MASTER",
              type: "servico"
            },
            {
              name: "SAFRA",
              type: "servico"
            },
            {
              name: "BANRISUL",
              type: "servico"
            },
            {
              name: "MERCANTIL",
              type: "servico"
            }
          ]
        },
        {
          name: "Crédito Pessoal",
          type: "categoria",
          items: [
            {
              name: "JB Cred",
              type: "servico"
            },
            {
              name: "Facta",
              type: "servico"
            }
          ]
        },
        {
          name: "Crédito na Conta de Luz",
          type: "categoria",
          items: [
            {
              name: "CREFAZ",
              type: "convenio",
              items: [
                {
                  name: "Neoenergia CELPE",
                  type: "servico"
                },
                {
                  name: "Neoenergia COSERN",
                  type: "servico"
                },
                {
                  name: "Neoenergia COELBA",
                  type: "servico"
                },
                {
                  name: "RGE/RGE - SUL",
                  type: "servico"
                },
                {
                  name: "Elektro de SP",
                  type: "servico"
                },
                {
                  name: "Elektro de MS",
                  type: "servico"
                },
                {
                  name: "ENEL de CE",
                  type: "servico"
                },
                {
                  name: "ENEL de RJ",
                  type: "servico"
                },
                {
                  name: "ENEL de SP",
                  type: "servico"
                }
              ]
            }
          ]
        }
      ]
    };
  },
  mounted() {
    var self = this;
    $("#app").click(function(e) {
      if ($(e.target).is(".overlay_custom")) {
        self.listaServ = "";
      }
    });
  },
  methods: {
    hoverMenu(val) {

      if (val) {
        if (val[0].type == "convenio") {
          this.listaConvenios = [];
          for (let index = 0; index < val.length; index++) {
            if (val[index].name == "INSS" || 
            val[index].name == "Forças Armadas" ||
            val[index].name == "Servidores Públicos" || 
            val[index].name == "CREFAZ") {
              this.listaConvenios.push(val[index]); 
            }
            if (val[index].name == "INSS" || 
            val[index].name == "Forças Armadas" ||
            val[index].name == "Servidores Públicos" ||
            val[index].name == "CREFAZ" ) {
              this.listaPublicos = [];
            }                 
          } 
          this.listaServ = ""; 
        } else if (val[0].type == "publico") {
          this.listaPublicos = [];
          this.listaServ = "";
          for (let index = 0; index < val.length; index++) {
            if (val[index].name == "Aposentados e Pensionistas" || 
            val[index].name == "Aeronáutica" ||
            val[index].name == "Exército" ||
            val[index].name == "Marinha" || 
            val[index].name == "Municipal" || 
            val[index].name == "Estadual" ||  
            val[index].name == "Federal") {
            this.listaPublicos.push(val[index]);  
            }      
          } 
        } else if (val[0].type == "servico") {
          this.listaServ = val;
          if (val[0].name == "BMG" || val[0].name == "JB Cred") {
            this.listaConvenios = [];
            this.listaPublicos = [];
          }
        }
      } else {
        this.listaConvenios = "";
        this.listaPublicos = "";
        this.listaServ = "";
      }
    },
    ifAdm() {
      if (this.$store.getters.getUser.profile == 1) {
        let cont = document.querySelector("#dropdownCont");
        console.log(cont);
        cont.style.left = "-1%";
      }
    },
    showModalDegustacaoDropdownServico() {
      this.$emit("showModalDegustacaoDropdownServico");
    }
  },
  mounted() {
    this.ifAdm();
  }
  // computed: {
  //   servicos() {
  //     return this.$store.state.servicos.servicosCategoria.filter(item => {
  //       return item.listService != "";
  //     });
  //   }
  // }
};
</script>

<style scoped>
.dropdown-menu {
  top: 45px;
  background-color: transparent;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 0;

  border: none;
}
</style>
