import axios from "axios";

let appApi = {
    baseURL: process.env.VUE_APP_FINANCIAL_API,
    responseType: "json",
    headers: {},
}

export const API = axios.create(appApi)

// {
//     "terminal":{
//       "chave":"4030707035"
//     }
//   }