import HTTP from "@/api_system";
import convertArray from "../../const/convertArray";
import store from './../index.js'

export default {
  state: {
    servicosCategoria: [],
    serviceDespachanteCategoria: [],
    servicesType: [],
    serviceDespachanteMaster: []
  },
  mutations: {
    set_servicoCategoria(state, val) {
      state.servicosCategoria = val;
    },
    set_serviceDespachanteCategoria(state, value) {
      state.serviceDespachanteCategoria = value;
    },
    set_servicesType(state, value) {
      state.servicesType = value;
    },
    set_serviceDespachanteMaster(state, value) {
      state.serviceDespachanteMaster = value;
    }
  },
  actions: {
    getServices({ commit, getters, dispatch }) {
      fetch(
        `${process.env.VUE_APP_ROOT_API}/api/services/app/Service/GetServicesByCategoryDto`,
        {
          method: "GET",
          headers: { Authorization: `bearer ${getters.getterToken}` }
        }
      )
        .then(response => response.json())
        .then(response => {
          commit("set_servicoCategoria", response.result.services);
          const { serviceDispatchers } = response.result
          commit("set_serviceDespachanteMaster", serviceDispatchers);
          if(serviceDispatchers) {
            commit("set_serviceDespachanteCategoria", serviceDispatchers);
            dispatch('updateServiceDespachante')
          }

        }).catch(err => {});
    },
    getIndicadores({ getters, dispatch }, tipo) {
      var url;
      if (tipo == "Trimestre") {
        url = "services/app/Indicators/GetIndicatorByQuarter";
      }
      if (tipo == "acumulado") {
        url = "services/app/Indicators/GetIndicatorByYear";
      }
      if (tipo == "mes" || tipo == "") {
        url = "services/app/Indicators/GetIndicatorByMonth";
      }

      return new Promise((resolve, reject) => {
        HTTP.get(url, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            var array = convertArray(response.data.result, tipo === 'acumulado' ? 'Ano' : tipo);
            console.log(response.data.result)
            resolve({
              indicadores: array,
              grafico: response.data.result.graphic
            });
          })
          .catch(response => { });
      });
    },
    getService({ commit, getters }, param) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_ROOT_API}/api/services/app/Service/GetServiceForEdit?id=${param}`, {
          method: "get",
          headers: { Authorization: `bearer ${getters.getterToken}` }
        })
          .then(response => response.json()).then(response =>
          {
            resolve(response.result);
          }).catch(err =>
          {
            reject(error.response);
          })
        })
    },
    CreateService({ commit, getters }, obj) {
      return new Promise((resolve, reject) => {
        HTTP.post(`${process.env.VUE_APP_ROOT_API}/api/services/app/Service/CreateService`, obj, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        }).then(response => {
          resolve(response.result)
        })
        .catch(error => {
          reject(error.response)
        })
      })
    },
    UpdateService({ commit, getters }, obj) {
      return new Promise((resolve, reject) => {
        HTTP.put(`${process.env.VUE_APP_ROOT_API}/api/services/app/Service/UpdateService`, obj, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        }).then(response => {
          resolve(response.result)
        })
        .catch(error => {
          reject(error.response)
        })
      })
    },
    DeleteService({ commit, getters }, param) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`${process.env.VUE_APP_ROOT_API}/api/services/app/Service/Delete?Id=${param}`, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        }).then(response => {
          resolve(response.data.success)
        })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    updateServiceDespachante({ state, commit, getters }) {
      const service = state.serviceDespachanteCategoria
      const serviceCategory = []
      const { get_tenant, getUser } = getters
      if (getUser.profile === 2) {
        service.forEach(item => {
          const items = {
            serviceName: item.name,
            id: item.id,
            categoryId: item.category,
            value: item.value,
            listService: [
              {
                name: 'Acompanhar',
                route: 'acompanhar',
                id: item.id
              },
            ]
          }

          serviceCategory.push(items)
        })
      }
      else {
        service.forEach(item => {
          const items = {
            serviceName: item.name,
            id: item.id,
            categoryId: item.category,
            value: item.value,
            listService: [
              {
                name: 'Acompanhar',
                route: 'acompanhar',
                id: item.id
              },
              {
                name: 'Solicitar',
                route: 'solicitar',
                id: item.id
              },
            ]
          }

          serviceCategory.push(items)
        })
      }

      const newValueDespathcer = [...serviceCategory]

      if (get_tenant.isDispatcher && getUser.profile === 2) {
        const isSolicitar = {
          serviceName: 'Solicitações',
          id: 'solicitacao',
          categoryId: 'Agendamentos e Cadastros',
          value: '',
          listService: [
            {
              name: 'Solicitações',
              route: 'acompanhar',
              id: 'solicitacao'
            },
            {
              name: 'Relatórios',
              route: 'relatorios',
              id: 'relatorios'
            },
          ]
        }
        newValueDespathcer.push(isSolicitar)
      }

      if (get_tenant.isDispatcher && getUser.profile === 3) {
        const isSolicitar = {
          serviceName: 'Solicitações',
          id: 'solicitacao',
          categoryId: 'Agendamentos e Cadastros',
          value: '',
          listService: [
            {
              name: 'Solicitações',
              route: 'acompanhar',
              id: 'solicitacao'
            },
          ]
        }

        newValueDespathcer.push(isSolicitar)
      }
      if(getUser.profile === 2 && !get_tenant.isDispatcher) {

        const isRelatorio = {
          serviceName: 'Relatórios',
          id: 'relatorios',
          categoryId: 'Agendamentos e Cadastros',
          value: '',
          listService: [
            {
              name: 'Relatórios',
              route: 'relatorios',
              id: 'relatorios'
            },
          ]
        }

        newValueDespathcer.push(isRelatorio)
      }
      if(getUser.profile === 2) {
        store.commit('setServiceDespachante', newValueDespathcer)
        return
      }
      store.commit('setServiceDespachante', get_tenant.isDispatcher ? newValueDespathcer : serviceCategory)
    },

    getServiceType({ commit, getters }) {
      fetch(
        `${process.env.VUE_APP_ROOT_API}/api/services/app/Service/GetTypeServices`,
        {
          method: "GET",
          headers: { Authorization: `bearer ${getters.getterToken}` }
        }
      )
        .then(response => response.json())
        .then(response => {
          commit("set_servicesType", response.result);
        })
        .catch(response => {});
    },
  },
  getters: {
    buscaService(state) {
      var category = state.servicosCategoria;
      var array = [];
      if (category) {
        category.forEach(element => {
          element.listService.forEach(item => {
            var obj = {
              categoryName: element.name,
              serviceName: item.name,
              id: item.id,
              categoryId: element.id,
              value: item.value
            };
            array.push(obj);
          });
        });
      }

      return array.sort(function(a, b) {
        if (a.serviceName < b.serviceName) {
          return -1;
        }
        if (a.serviceName > b.serviceName) {
          return 1;
        }

        return 0;
      });
    },
    getServiceDespachanteLength (state) {
      return state.serviceDespachanteCategoria.length
    },
    serviceDespachanteCategoria (state) {
      return state.serviceDespachanteCategoria
    },
    getServiceDespachanteMaster (state) {
      return state.serviceDespachanteMaster
    }
  }
};
