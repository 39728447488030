import HTTP from '@/api_system'
export default {
  state: {
    serviceDespachante: [],
    serviceTags: [],
    dataService: [],
    serviceDespachanteStatus: false,
    accompanyServiceDespachante: {
      result: {},
      load: false
    },
    accompanyServiceDespachanteController: {
      result: {},
      load: false
    },
    detailsServiceDespachante: {
      result: {},
      load: false
    },
    itemCardService: {},
    tenantIdCurrentDelete: null,
    tagsAll: [],
    statusAll: [],
    notificationStatus: [],
    tagCurrent: null,
    page: 1,
    numberOfPages: 0,
    closeFilter: false,
    urlFilter: '',
    uploadFilesItens: [],
    openButtonSave: false,
    dataItemDetails: []
  },

  mutations: {
    setServiceDespachante (state, newValue) {
      return state.serviceDespachante = newValue
    },

    setServiceTags (state, newValue) {
      return state.serviceTags = newValue
    },

    setDataService (state, newValue) {
      return state.dataService = newValue
    },

    setServiceDespachanteStatus (state, newValue) {
      return state.serviceDespachanteStatus = newValue
    },

    setAccompanyServiceDespachante (state, newValue) {
      return state.accompanyServiceDespachante = newValue
    },

    setAccompanyServiceDespachanteController (state, newValue) {
      return state.accompanyServiceDespachanteController = newValue
    },

    setDetailsServiceDespachante (state, newValue) {
      return state.detailsServiceDespachante = newValue
    },

    setItemCardService (state, newValue) {
      return state.itemCardService = newValue
    },

    setTenantIdCurrentDelete (state, newValue) {
      return state.tenantIdCurrentDelete = newValue
    },

    setTagsAll (state, newValue) {
      return state.tagsAll = newValue
    },

    setStatusAll (state, newValue) {
      return state.statusAll = newValue
    },

    setNotification (state, newValue) {
      return state.notificationStatus = newValue
    },

    setTagCurrent (state, newValue) {
      return state.tagCurrent = newValue
    },

    setPage (state, newValue) {
      return state.page = newValue
    },

    setNumberOfPages (state, newValue) {
      return state.numberOfPages = newValue
    },

    setCloseFilter (state, newValue) {
      return state.closeFilter = newValue
    },

    setUrlFilter (state, newValue) {
      return state.urlFilter = newValue
    },

    setUploadFilesItens (state, newValue) {
      return state.uploadFilesItens = newValue
    },

    setOpenButtonSave (state, newValue) {
      return state.openButtonSave = newValue
    },

    setDataItemDetails (state, newValue) {
      return state.dataItemDetails = newValue
    },
  },

  getters: {
    getServiceDespachante (state) {
      return state.serviceDespachante
    },

    getServiceTags (state) {
      return state.serviceTags
    },

    getDataService (state) {
      return state.dataService
    },

    getServiceDespachanteStatus(state) {
      return state.serviceDespachanteStatus
    },

    getAccompanyServiceDespachante (state) {
      return state.accompanyServiceDespachante
    },

    getAccompanyServiceDespachanteController (state) {
      return state.accompanyServiceDespachanteController
    },

    getdetailsServiceDespachante (state) {
      return state.detailsServiceDespachante
    },

    getItemCardService (state) {
      return state.itemCardService
    },

    getTenantIdCurrentDelete (state) {
      return state.tenantIdCurrentDelete
    },

    getTagsAll (state) {
      return state.tagsAll
    },

    getStatusAll (state) {
      return state.tagsAll
    },

    getNotificationStatus (state) {
      return state.notificationStatus
    },

    getTagCurrent (state) {
      return state.tagCurrent
    },

    getPage (state) {
      return state.page
    },

    getNumberOfPages (state) {
      return state.numberOfPages
    },

    getCloseFilter (state) {
      return state.closeFilter
    },

    getUrlFilter (state) {
      return state.urlFilter
    },

    getUploadFilesItens (state) {
      return state.uploadFilesItens
    },

    getOpenButtonSave (state) {
      return state.openButtonSave
    },

    getDataItemDetails (state) {
      return state.dataItemDetails
    }
  },

  actions: {
    sendDespanchanteFinish({ getters }) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcher/SendCompleteOrder?orderId=${getters.getOrderId}`,{} ,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getAllAccompanyFilter({ getters }, urlFilter) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceDispatcher/GetAllAccompany${urlFilter}`,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getAllControllerFilter({ getters }, urlFilter) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceDispatcher/GetAllController${urlFilter}`,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getServiceStatus({ getters }, id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceDispatcher/GetServiceStatus`,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getDetails({ getters, commit }, item) {
      return new Promise((resolve, reject) => {
        commit("setDetailsServiceDespachante", { result: {}, load: true });
        const url = item.tenantId ? `&tenantId=${item.tenantId}` : '';
        HTTP.get(`services/app/ServiceDispatcher/GetDetails?id=${item.id + url}`,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    deleteServiceDoc({ getters }, id) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`services/app/OrderServiceFile/Delete?Id=${id}`,{ headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    ApproveOrRecuseService({ getters }, item) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcher/ApproveOrRecuseService`, item ,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    InsertVehiclePlate({ getters }, item) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcher/InsertVehiclePlate`, item ,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    sendScheduling({ getters }, item) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcherActivities/SendScheduling`, item ,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    ModifyStateService({ getters }, item) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcher/ModifyStateService`, item ,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    sendMessageDispatcher({ getters }, params) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcherActivities/SendMessage`, params ,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
          .catch(err => {
            if (err.response.data.error.code == 404) {
              reject({ msg: err.response.data.error.message })
            }
            else {
              reject({ msg: 'Ops, ocorreu um erro ao enviar a mensagem' })
            }
        })
      })
    },
    sendMessageFile({ getters }, {id, tenantId, inputFile}) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcherActivities/SendImage?ServiceDispatcherId=${id}${tenantId ? `&tenantId=${tenantId}` : ''}`, inputFile ,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
          .catch((error) => {
            if (!error.response || error.response.data.error.code != 413) {
              reject("Não foi possivel realizar o upload, tente novamente.");
            }
          reject(error.response.data.error.message);
        })
      })
    },
    getNewMessages({ getters }, {id, tenantId}) {
      const { getterToken } = getters
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceDispatcherActivities/GetNewMessages?serviceDispatcherId=${id}${tenantId ? `&tenantId=${tenantId}` : ''}`,{ headers: { Authorization: `bearer ${getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    senVisualized({ getters }, {id, tenantId}) {
      const { getterToken } = getters
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcher/Visualized?serviceDispatcherId=${id}${tenantId ? `&tenantId=${tenantId}` : ''}`,{}, { headers: { Authorization: `bearer ${getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getFiles({ getters }, {id, tenantId, item}) {
      console.log(id,tenantId)
      const { getterToken } = getters
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceDispatcherActivities/GetFile?hashName=${item}&serviceDispatcherId=${id}${tenantId ? `&tenantId=${tenantId}` : ''}`,{ headers: { Authorization: `bearer ${getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getFilesDowload({ getters }, {tenantId, orderServiceId}) {
      const { getterToken } = getters
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/OrderServiceFile/GetFileDownload?orderServiceId=${orderServiceId}${tenantId ? `&tenantId=${tenantId}` : ''} `,{ headers: { Authorization: `bearer ${getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    deleteService({ getters }, id) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`services/app/ServiceDispatcher/Delete?Id=${id}`,{ headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    deleteMessage({ getters }, id) {
      const { getTenantIdCurrentDelete, tenant } = getters
      return new Promise((resolve, reject) => {
        HTTP.delete(`services/app/ServiceDispatcherActivities/Delete?Id=${id}${tenant.isDispatcher ? `&tenantId=${getTenantIdCurrentDelete}` : ''}`,{ headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getTags({ getters }) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceDispatcher/GetTagStatus`, { headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getTagsFilter({ getters }, { tagValue, tenantId }) {
      console.log(tagValue, tenantId)
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceDispatcher/GetTagStatus?status=${tagValue}&tenantId=${tenantId}`, { headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getStatusAndTags({ getters }, { tenantId }) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceDispatcher/GetStatusAndTags?tenantId=${tenantId}`, { headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getImageFluxo({ getters }, id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/ServiceParametrization/GetImage?id=${id}`, { headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getNotificationStatus({ getters, commit }) {
      HTTP.get(`services/app/ServiceDispatcher/GetNotifications`, { headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          commit('setNotification', res.data.result)
          const { existNotification, notificationsController } = res.data.result
          if(existNotification && getters.tenant.isDispatcher) {
            document.title = `(${notificationsController} | Despachante) Ponto-Facil`
            return
          }
          document.title = `Sistema - Ponto-Facil`
        })
        .catch(err => {})
    },
    getExcel({ getters }, id) {
      return new Promise((resolve, reject) => {
        HTTP.get(`services/app/Report/GetExport?type=${id}`, { headers: { Authorization: `bearer ${getters.getterToken}` } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

  },


};
