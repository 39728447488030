export default {
  state: {
    component: "Users",
    menuSubmenu: []
  },
  mutations: {
    changeComponent(state, newValue) {
      return (state.component = newValue);
    },
    menuInstallment(state, newValue) {
      return (state.menuSubmenu = newValue)
    }
  },
  actions: {},
  getters: {
    componentCurrentValue: function(state) {
      return state.component;
    }
  }
};
