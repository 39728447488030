<template>
	<div class="dropdown-menu sub_menu_dp px-1 py-0" id="dropdownCont">
		<Menu @hoverMenu="hoverMenu" :servicos="servicos"/>
		<subMenu :listaServ.sync="listaServ" @openModalInstrucoes="openModalInstrucoes" @showModalDegustacaoDropdownServico="showModalDegustacaoDropdownServico"/>
		<modalInstrucoes :superObj="superObj" @closeModalInstrucoes="closeModalInstrucoes"/>
	</div>
</template>

<script>
  import HTTP from "@/api_system";
	import Menu from "./_Menu";
	import subMenu from "./_Sub_categoria_dp";
	import modalInstrucoes from "./modal/modalInstrucoes";
	export default {
		components: {
			subMenu,
			Menu,
      modalInstrucoes
		},
		data() {
			return {
				listaServ: [],
        superObj: {
        instructions: [],
        link: "",
        name: ""
      }
			};
		},
		mounted() {
			var self = this;
			$("#app").click(function (e) {
				if ($(e.target).is(".overlay_custom")) {
					self.listaServ = "";
				}
			});
		},
		methods: {
			hoverMenu(val) {
				if (val) {
					this.listaServ = val;
				} else {
					this.listaServ = "";
				}
			},
			ifAdm(){
				if(this.$store.getters.getUser.profile == 1){
					let cont = document.querySelector('#dropdownCont');
					console.log(cont)
					cont.style.left = "-1%"
				}
			},
			openModalInstrucoes(item) {
			  this.getService(item, this.superObj);
        $('#modalInstrucoes').appendTo('body').modal('show');
			},
			closeModalInstrucoes() {
        $('#modalInstrucoes').modal('hide');
			},
      async getService(item, superObj) {
        try {
          const { data } = await HTTP.get(`services/app/Service/GetService?id=${item.id}`, { headers: { Authorization: `bearer ${this.$store.getters.getterToken}`}})
          superObj.instructions = data.result.instructions;
          superObj.link = data.result.service.link;
        } catch (error)  {
          console.error(error)
          this.toast('Ops algo deu errado', this.$toast.error)
        }
      },
      showModalDegustacaoDropdownServico() {
				this.$emit("showModalDegustacaoDropdownServico");
			}
		},
		mounted(){
			this.ifAdm()
		},
		computed: {
			servicos() {
				return this.$store.state.servicos.servicosCategoria.filter((item) => {
					return item.listService != "";
				});
			},
		},
	};
</script>

<style scoped>
.dropdown-menu {
	top: 45px;
	background-color: transparent;
	padding-right: 15px;
	padding-left: 15px;
	margin-top: 0;

	border: none;
}
</style>
