import HTTP from '@/api_system'

export default {
  state: {
    dateInit: "",
    dateFinish: "",
    listCsv: []
  },
  mutations: {
    valueDateInit(state, newValue) {
      return (state.dateInit = newValue);
    },
    valueDateFinish(state, newValue) {
      return (state.dateFinish = newValue);
    },
    setListCsv(state, newValue) { // se precisar atulizar
      return state.listCsv = newValue
    }
  },
  actions: {
    requestDataListCsv ({ getters }, id) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/Cashier/ExportCashiesSallesmanOrFranck?id=${id}`, {}, { headers: { Authorization: `bearer ${getters.getterToken}` } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {
    dateInit(state) {
      return state.dateInit;
    },
    dateFinish(state) {
      return state.dateFinish;
    },
    getListCsv(state) { // se precisar atulizar
      return state.listCsv
    }
  }
};
