import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import store from '../store/index'
import { redirectEnter, validaLoginStep, requireAuth, nivelAcesso, validaPedidos, validaVendedor, master ,validaFranqueado} from '../const/nivelAcesso'
Vue.use(VueRouter)
    // allows using multiple guards for routes
function guards(guards) {
    return async(to, from, next) => {

        // a flag to discard remaining guards
        let changed = false;

        // handle next for multiple guards
        const mNext = function(value) {
            // prevent calling next after it is already resolved with a value
            if (changed) return;

            // if we have 'value' reslove next with the value and set changed flag
            if (typeof value != 'undefined') {
                changed = true;
                next(value);
            }
        };

        // call guards
        for (let i = 0; i < guards.length; i++) {
            if (changed) break;
            await guards[i](to, from, mNext);
        }

        // move on if none of guards resolved next with a value
        if (!changed) next();

    }
}

const routes = [
  {
    path: "/unidade",
    name: "unidade",

    component: () => import("../views/login/getUnidade.vue"),

    meta: {
      no_header: false
    }
  },
  {
    path: "/unidadeCpf",
    name: "unidadeCpf",

    component: () => import("../views/login/unidadeCpfMobile.vue"),

    meta: {
      no_header: false
    }
  },
  {
    path: "/finishMobile",
    name: "finishBoxMobile",

    component: () => import("../views/login/finishBoxMobile.vue"),

    meta: {
      no_header: false
    }
  },
  {
    path: "/openCashRegister",
    name: "caixaAbertoMobile",

    component: () => import("../views/login/caixaAbertoMobile.vue"),

    meta: {
      no_header: false
    }
  },
  {
    path: "/login",
    name: "login",
    component: login,
    beforeEnter: guards([validaLoginStep, redirectEnter]),

    meta: {
      no_header: false
    }
  },
  {
    path: "/senha",
    name: "senha",

    component: () => import("../views/login/senha.vue"),
    beforeEnter: validaLoginStep,
    meta: {
      no_header: false
    }
  },

  {
    path: "/esqueci-a-senha",
    name: "esqueci-a-senha",

    component: () => import("../views/login/esqueciEsenha"),

    meta: {
      no_header: false
    }
  },
  {
    path: "/abrir-caixa",
    name: "abrir-caixa",

    component: () => import("../views/login/abrirCaixa"),
    beforeEnter: validaLoginStep,

    meta: {
      no_header: false
    }
  },
  {
    path: "/trocar-senha",
    name: "trocar-senha",

    component: () => import("../views/login/trocarSenha"),

    meta: {
      no_header: false
    }
  },
  {
    path: "/logout",
    name: "logout",

    component: () => import("../views/logout.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: false
    }
  },

  {
    path: "/",
    name: "dashboard",

    component: () => import("../views/dashboard.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/mural",
    name: "mural",

    component: () => import("../views/mural.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/caixa",
    name: "caixa",

    component: () => import("../views/caixa.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/pedido",
    name: "pedido",

    component: () => import("../views/pedido.vue"),
    beforeEnter: guards([requireAuth, validaPedidos, validaVendedor]),
    meta: {
      no_header: true
    }
  },
  {
    path: "/finalizar-servico",
    name: "finalizarServico",

    component: () => import("../views/finishedServices.vue"),
    beforeEnter: guards([requireAuth, validaPedidos, validaVendedor]),
    meta: {
      no_header: true
    }
  },
  {
    path: "/finalizar-servicoBB",
    name: "finalizarServicoBB",

    component: () => import("../views/finishedServicesBB.vue"),
    // beforeEnter: guards([requireAuth, validaPedidos, validaVendedor]),
    beforeEnter: guards([requireAuth, validaVendedor]),
    meta: {
      no_header: true
    }
  },
  {
    path: "/pedidos",
    name: "pedidos",

    component: () => import("../views/pedidos.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/diario-de-bordo",
    name: "diariodeBordo",
    component: () => import("../views/diariodeBordo.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/estoque",
    name: "estoque",

    component: () => import("../views/estoque.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/despesas",
    name: "despesas",

    component: () => import("../views/despesas.vue"),
    beforeEnter: guards([requireAuth, validaFranqueado]),
    meta: {
      no_header: true
    }
  },
  {
    path: "/servico/:id",
    name: "servico",

    component: () => import("../views/servico.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },

  {
    path: "/metas",
    name: "metas",

    component: () => import("../views/metas.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/perfil",
    name: "perfil",

    component: () => import("../views/perfil.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/notificacoes",
    name: "notificacoes",

    component: () => import("../views/notificacoes.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/administrativo",
    name: "administrativo",

    component: () => import("../views/administrativo.vue"),
    beforeEnter: nivelAcesso,
    meta: {
      no_header: true
    }
  },
  {
    path: "/indicadores",
    name: "indicadores",

    component: () => import("../views/indicadores.vue"),
    beforeEnter: nivelAcesso,
    meta: {
      no_header: true
    }
  },
  {
    path: "/solicitacao",
    name: "solicitacao",

    component: () => import("../views/solicitacao.vue"),
    beforeEnter: guards([requireAuth, master]),
    meta: {
      no_header: true
    }
  },
  {
    path: "/suporte/:id",
    name: "suporte",

    component: () => import("../views/suporte.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/dados",
    name: "dados",

    component: () => import("../views/dados.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/data",
    name: "data",

    component: () => import("../views/dataTeste.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/banners",
    name: "banners",

    component: () => import("../views/banners.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/auditoria",
    name: "auditoria",

    component: () => import("../views/auditoria.vue"),
    beforeEnter: master, requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/arquivos",
    name: "arquivos",

    component: () => import("../views/arquivos.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    },
    children: [
      {
        name: 'arquivo',
        path: ':id',
        component: () => import('@/components/arquivos/containerFolder'),
        beforeEnter: requireAuth,
        meta: {
          no_header: true
        },
      }
    ]

  },
  {
    path: "/unidades",
    name: "unidades",

    component: () => import("../views/getUnidades.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    }
  },
  {
    path: "/despachante/:id",
    name: "despachante",
    component: () => import("../views/despachante/dashDespachante.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    },
    // children: [
    //   {
    //     name: 'despachanteContainer',
    //     path: ':id',
    //     component: () => import('@/components/despachante/containerDespachante.vue'),
    //     beforeEnter: requireAuth,
    //     meta: {
    //       no_header: true
    //     },
    //   }
    // ]
  },
  // {
  //   path: "/despachante/:id",
  //   name: "despachanteContainer",
  //   component: () => import('@/components/despachante/containerDespachante.vue'),
  //   beforeEnter: requireAuth,
  //   meta: {
  //     no_header: true
  //   },

  // },
  // {
  //   path: "/acompanhar",
  //   name: "acompanhar",
  //   component: () => import("../views/despachante/acompanhar.vue"),
  //   beforeEnter: requireAuth,
  //   meta: {
  //     no_header: true
  //   },
  //   children: [
  //     {
  //       name: 'despachanteContainer',
  //       path: ':id',
  //       component: () => import('@/components/despachante/acompanharDespachante.vue'),
  //       beforeEnter: requireAuth,
  //       meta: {
  //         no_header: true
  //       },
  //     }
  //   ]
  // },
  {
    path: "/acompanhar/:id",
    name: "acompanhar",
    component: () => import("../views/despachante/acompanhar.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    },
  },
  {
    path: "/relatorios",
    name: "relatorios",
    component: () => import("../views/despachante/relatorios.vue"),
    beforeEnter: requireAuth,
    meta: {
      no_header: true
    },
    // children: [
    //   {
    //     name: 'despachanteContainer',
    //     path: ':id',
    //     component: () => import('@/components/despachante/acompanharDespachante.vue'),
    //     beforeEnter: requireAuth,
    //     meta: {
    //       no_header: true
    //     },
    //   }
    // ]
  },
];

const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
    // router.beforeEach((to, from, next) => {
    //     // var de = from
    //     // var para = to
    //     // const host = window.location.host.split(".")
    //     // if (to.name == '/unidade') {
    //     //     next()
    //     // }
// router.beforeEach((to, from, next) => {
//   console.log(store.getters);
//   if (store.state.login.userLogin.profile === 3 && store.getters.getterToken != undefined && store.getters.getterToken != "") {
//     if (store.state.terminalTef.tokenZigNet === undefined || store.state.terminalTef.tokenZigNet === "") {
//       store.dispatch("GetTokenZigNet");
//     }
//     else {
//       store.dispatch("GetCheckPinPad");
//     }

//   }
//   next()
// })




//     // to and from are both route objects. must call `next`.
// })
export default router
