import vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import login from './js/login'
import caixa from './js/caixa'
import servicos from './js/servicos'
import suporte from "./js/suporte";
import pessoas from './js/pessoas'
import notifique from './js/notifique'
import tenent from './js/tenent'
import pedidos from './js/pedidos'
import solicitacao from "./js/solicitacao"
import navegation from "./js/navegation"
import franqueadoEmaster from './js/franqueadoEmaster'
import vendedor from './js/vendedor'
import dadosCsv from './js/dadosCsv'
import SecureLS from "secure-ls"
import logoutCaixa from "./js/logoutCaixa"
import valuePays from "./js/valuePays";
import installment from './js/installment'
import usersFinancial from './js/userFinancial'
import progress from './js/toast'
import terminalTef from './js/terminalTef'
import terminalTefLocal from './js/terminalTefLocal'
import upload from './js/upload'
import cities from './js/cities'
import serviceId from './js/serviceID'
import despachante from './js/despachante/main'
import despachanteRelatorios from './js/despachante/relatorios'
import signal from './js/signal'
import mobile from './js/mobile/mobile'
import banners from './js/banners'
import indexBB from './js/bancoBB/indexBB'

var ls = new SecureLS({ encodingType: 'base64', isCompression: true })
vue.use(Vuex)
const vuexPersist = new VuexPersist({
    key: 'ponto-facil-app',
    storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
    }
})
let initialState = {
  login: login.state,
  caixa: caixa.state,
  servicos: servicos.state,
  suporte: suporte.state,
  pessoas: pessoas.state,
  notifique: notifique.state,
  tenent: tenent.state,
  pedidos: pedidos.state,
  solicitacao: solicitacao.state,
  navegation: navegation.state,
  dadosCsv: dadosCsv.state,
  logoutCaixa: logoutCaixa.state,
  valuePays: valuePays.state,
  installment: installment.state,
  usersFinancial: usersFinancial.state,
  progress: progress.state,
  terminalTef: terminalTef.state,
  terminalTefLocal: terminalTefLocal.state,
  upload: upload.state,
  cities: cities.state,
  serviceId: serviceId.state,
  despachante: despachante.state,
  despachanteRelatorios: despachanteRelatorios.state,
  signal: signal.state,
  mobile: mobile.state,
  banners: banners.state,
  indexBB: banners.indexBB,
};
export default new Vuex.Store({
  mutations: {
    resetAll(state) {
      Object.keys(state).forEach(key => {
        Object.assign(state[key], initialState[key]);
      });
    }
  },
  plugins: [vuexPersist.plugin],
  modules: {
    login: login,
    caixa: caixa,
    servicos: servicos,
    suporte: suporte,
    pessoas: pessoas,
    notifique: notifique,
    tenent: tenent,
    pedidos: pedidos,
    solicitacao: solicitacao,
    navegation: navegation,
    franqueadoEmaster: franqueadoEmaster,
    vendedor: vendedor,
    dadosCsv: dadosCsv,
    logoutCaixa: logoutCaixa,
    valuePays: valuePays,
    installment: installment,
    usersFinancial: usersFinancial,
    progress: progress,
    terminalTef: terminalTef,
    terminalTefLocal: terminalTefLocal,
    upload: upload,
    cities: cities,
    serviceId: serviceId,
    despachante: despachante,
    despachanteRelatorios: despachanteRelatorios,
    signal: signal,
    mobile: mobile,
    banners: banners,
    indexBB: indexBB
  }
});
