import HTTP from "@/api_system"
export default {
  state: {
    pedidos: "",
    clientDto: null,
    update: false,
    cpfNull: false,
    payAll: [],
    discard: null,
    newPedidos: [],
    stateWatch: false,
    cpCurrent: 'checkService',
    saveUser: false
  },
  mutations: {
    set_pedido(state, val) {
      state.pedidos = val;
    },
    userCliente(state, newValue) {
      //! novo cadastro
      return (state.clientDto = newValue);
    },
    updateClienteData(state, newValue) {
      //! atualizar dados
      return (state.update = newValue);
    },
    cpfUndefined(state, newValue) {
      //! cpf null dados não sabe
      return (state.cpfNull = newValue);
    },
    payValue(state, newValue) {
      //! valor de pagamento
      return (state.payAll = newValue);
    },
    newDiscard(state, newState) {
      return (state.discard = newState);
    },
    newPedidos(state, newState) {
      return (state.newPedidos = newState);
    },
    setStateWatch(state, newState) {
      return (state.stateWatch = newState);
    },
    setCpCurrent(state, newState) {
      return (state.cpCurrent = newState);
    },
    setSaveUser(state, newState) {
      return (state.saveUser = newState);
    },

  },
  getters: {
    user(state) {
      return state.clientDto;
    },
    updateUser(state) {
      return state.update;
    },
    cpfUserUndefined(state) {
      return state.cpfNull;
    },
    payValueGet(state) {
      return state.payAll
    },
    getpedidos(state) {
      return state.pedidos
    },
    getDiscard(state) {
      return state.discard
    },
    getNewPedidos(state) {
      return state.newPedidos
    },
    getStateWatch(state) {
      return state.stateWatch
    },
    getCpCurrent(state) {
      return state.cpCurrent
    },
    getSaveUser(state) {
      return state.saveUser
    },
  },
  actions: {
    finishPedido({ commit, getters, state }, id) {
      return new Promise((resolve, reject) => {
        HTTP.post(
          `services/app/Order/CompleteOrder`,
          {
            orderId: id,
            clientId: state.clientDto,
            paymentValuesDto: state.payAll
          },
          {
            headers: {
              Authorization: `Bearer ${getters.getterToken}`
            }
          }
        )
          .then(response => {
            // commit('set_userScore', response.data.result)
            // console.log(response.data);
            // console.log(response);
            resolve(response);
          })
          .catch(error => {
            //console.dir(form);
            reject(error);
          });
      });
    },
  }
};
