<template>
  <div
    class="modal fade"
    :id="modalId"
    role="dialog"
    tabindex="-1"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div
      :class="`modal-dialog modal-dialog-centered ${typeLg}`"
      role="document"
    >
      <div class="modal-content container">
        <div class="modal-body px-4 py-4">
          <button type="button" class="close" @click="closeModal()">
            <span aria-hidden="true"
              ><img src="@/assets/image/icones/closing.svg"
            /></span>
          </button>
          <p class="titulo_dash mb-2">
            {{ modalTitle }} <img v-if="icon != undefined" :src="require(`@/assets/image/icones/${icon}`)" /> {{subTitle}}
          </p>
          <div class="spinner" v-if="load">
            <half-circle-spinner
              :animation-duration="1000"
              :size="40"
              color="#f38235"
            />
          </div>
          <slot v-else></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";

export default {
  components:{ HalfCircleSpinner },
  props: ["modalId", "modalTitle", "typeLg", "load", "icon", "subTitle"],
  mounted() {
    this.getScript();
  },

  methods: {
    closeModal() {
      $(`#${this.modalId}`).modal("hide");
      this.$emit("closeModal");
    },
    getScript() {
      $(".modal").on({
        "show.bs.modal": function() {
          var idx = $(".modal:visible").length;
          $(this).css("z-index", 1040 + 10 * idx);
        },
        "shown.bs.modal": function() {
          var idx = $(".modal:visible").length - 1; // raise backdrop after animation.
          $(".modal-backdrop")
            .not(".stacked")
            .css("z-index", 1039 + 10 * idx)
            .addClass("stacked");
        },
        "hidden.bs.modal": function() {
          if ($(".modal:visible").length > 0) {
            // restore the modal-open class to the body element, so that scrolling works
            // properly after de-stacking a modal.
            setTimeout(function() {
              $(document.body).addClass("modal-open");
            }, 0);
          }
        }
      });
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.modal_wd {
  max-width: 730px !important;
}
</style>
