<template>
  <div class="dropdown-menu sub_menu_despachante px-1 py-0">
    <Menu @hoverMenu="hoverMenu" :servicos="servicos" :serviceShow="false"></Menu>
    <subMenu :listaServ.sync="listaServ" class="subMenu" @showModalDegustacao="showModalDegustacao"/>
  </div>
</template>

<script>
import Menu from "./_menuDespachante.vue";
import subMenu from "./menuDespachante.vue";
export default {
  components: {
    subMenu,
    Menu
  },
  data() {
    return {
      listaServ: []
    };
  },
  mounted() {
    var self = this;
    $("#app").click(function(e) {
      if ($(e.target).is(".overlay_custom")) {
        self.listaServ = "";
      }
    });
    this.comparSize()
  },
  methods: {
    hoverMenu(val) {
      if (val) {
        this.listaServ = val;
      } else {
        this.listaServ = "";
      }
      $(".menu").css({ left: "0" });
    },   
    comparSize(){
      if($(window).width() > 767){
        $(".menu").css({ left: "-155px" });
      }else{
        $(".menu").css({ left: "-155px"});
      }
    },
    showModalDegustacao() {
      this.$emit("showModalDegustacao");
    }
  },
  computed: {
    servicos() {
      return this.$store.getters.getServiceDespachante.filter(item => {
        return item.listService != "";
      });
    }
  }
};
</script>

<style scoped>
.dropdown-menu {
  top: 45px;
  background-color: transparent;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 0;
  border: none;
}
@media screen and (max-width:767px){
  .dropdown-menu{
    position: absolute!important;
    left: 0!important;
    opacity: 1;
    top: 104%!important;
    background-color: transparent;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 0;
    border: none;
  }
}
</style>
