<template>
  <div class="menu">
    <div
      class="dropdown-item d-flex align-items-center py-0"
      v-for="(item, index) in listaServ"
      :key="index"
      @click="redirectRouterService(item, index)"
    >
      <div class="col-md-12 p-1">
        <p class="mb-0">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["listaServ"],
  computed: {
    user() {
      return this.$store.getters.getUser.profile;
    }
  },
  methods: {
    redirectRouterService({ route, name, id }, index) {
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        this.$emit("showModalDegustacao");
      } else {
        // console.log(this.listaServ[0].id)
        // if(this.user !== 3) {
        //   this.toast("Para Realizar algum serviço acessa o perfil vendedor!", this.$toast)
        //   return
        // }
        this.$store.commit("setServiceTags", this.listaServ[index]);

        if (route === "acompanhar" && this.user == 3) {
          this.$router
            .push(`/acompanhar/${this.listaServ[0].id}`)
            .catch(err => {});
          // this.$router.push(`/acompanhar/acompanhar`).catch(err => {})
          return;
        }

        if (
          route === "acompanhar" &&
          this.user == 2 &&
          !this.$store.getters.get_tenant.isDispatcher
        ) {
          this.$router
            .push(`/acompanhar/${this.listaServ[0].id}`)
            .catch(err => {});
          // this.$router.push(`/acompanhar/acompanhar`).catch(err => {})
          return;
        }
        if (
          route === "acompanhar" &&
          this.user == 2 &&
          this.$store.getters.get_tenant.isDispatcher
        ) {
          this.$router
            .push(
              `/acompanhar/${
                this.listaServ.length > 1
                  ? this.listaServ[1].id
                  : this.listaServ[0].id
              }`
            )
            .catch(err => {});
          // this.$router.push(`/acompanhar/acompanhar`).catch(err => {})
          return;
        }

        if (route === "acompanhar" && this.user != 3) {
          this.$router
            .push(`/acompanhar/${this.listaServ[1].id}`)
            .catch(err => {});
          // this.$router.push(`/acompanhar/acompanhar`).catch(err => {})
          return;
        }

        if (route === "relatorios") {
          this.$router.push(`/acompanhar/relatorios`).catch(err => {});
          return;
        }

        this.$router.push({ name: "despachante", params: { id: id } });
        // this.$router.push({ path: `/despachante/${String(id)}`})
        // this.$router.push(`/despachante/${id}`).then(item => console.log(item)).catch(err => {console.log(err)})
        // console.log(this.$route)
        // this.$router.push(`/despachante/${id}`)
      }
    }
  }
};
</script>

<style scoped>
.dropdown-item {
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  height: 60px;
  color: #868686;
  font-size: 12px;
  white-space: normal;
  transition: all 0.2s linear;
  direction: ltr;
  cursor: pointer;
}
.dropdown-item:hover {
  /* background-color: #f38235; */
  color: #f38235;
}
.menu {
  max-height: 70vh;
  overflow: auto;
  overflow-x: hidden;
  /* direction: rtl; */
  width: 160px;
  background: #fff;
  border-radius: 0 6px 6px 0;
  position: relative;
  left: -155px;
  z-index: -1;
  transition: 0.2s ease-in-out;
}
.menu::-webkit-scrollbar {
  width: 2px;
  background: #fff;
}

.menu::-webkit-scrollbar-thumb {
  background-color: #f38235;
}
.menu::-webkit-scrollbar-track {
  background-color: #fff;
}
p {
  font-weight: 400;
  font-size: 13px;
}
@media screen and (max-width: 767px) {
  .menu {
    width: 140px !important;
  }
}
</style>
