<template>
  <div class="menu">
    <div class="menuSubCont" v-if="!get_tenant.isDispatcher">
      <a
        :class="
          `dropdown-item d-flex align-items-center mb-0 py-0 ${concatName(
            item.serviceName
          )}`
        "
        href="#"
        v-for="(item, index) in filterServicos"
        :key="index"
        @click="hoverMenuMobile(item, concatName(item.serviceName))"
        @mouseover="hoverMenu(item, concatName(item.serviceName))"
      >
        <div
          class="col-md-10 d-flex align-items-center justify-content-between p-0"
          id="contentCont"
        >
          <p class="m-0 col-7 ">{{ item.serviceName }}</p>
          <p class="m-0 col-4">{{ valueConvert(item.value) }}</p>
          <p
            v-if="
              getNotificationStatus &&
                getNotificationStatus.notificationsServices &&
                getNotificationStatus.notificationsServices.some(
                  s => s.serviceId == item.id && s.notification
                )
            "
            class="notificationAccompany m-0 "
          ></p>
          <p v-else></p>
        </div>
        <div class="col-md-2 border_shadown">
          <i class="fas fa-chevron-right"></i>
        </div>
      </a>
    </div>
    <a
      href="#"
      :class="
        `newService d-flex align-items-center mb-1 p-0 ${concatName(
          'Solicitar'
        )}`
      "
      @click="hoverMenuMobile(servicos.at(-1), concatName('Solicitar'))"
      @mouseover="hoverMenu(servicos.at(-1), concatName('Solicitar'))"
      v-if="get_tenant.isDispatcher"
    >
      <div class="col-md-10 d-flex align-center justify-content-between">
        <p class="mb-0 controller">Controle de Solicitação</p>
      </div>
      <div class="notificationController px-2 mx-2">
        {{ getNotificationStatus.notificationsController }}
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    serviceShow: {
      type: Boolean,
      default: true
    },
    servicos: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapGetters([
      "serviceDespachanteCategoria",
      "getNotificationStatus",
      "get_tenant"
    ]),
    filterServicos() {
      const filterService = this.servicos.filter(
        item => item.serviceName != "Solicitações"
      );
      return filterService;
    },
    filterServicosRelatorio() {
      return this.servicos.filter(item => item.serviceName === "Relatórios");
    },
    filterServicosRemoveRelatorio(item) {
      if (this.$store.getters.getUser.profile === 2) return item;

      return item.listService.filter(item => item.name != "Relatórios");
    }
  },
  methods: {
    hoverMenu(val, className) {
      console.log(val);
      const { listService } = val;
      [...$(".dropdown-item")].forEach(element => {
        element.classList.remove("active");
      });

      $(`.${className}`).addClass("active");
      if (listService) {
        this.$emit("hoverMenu", this.sortArrayServices(val.listService));
        return;
      }
    },
    hoverMenuMobile(val, className) {
      if ($(window).width() > 767) {
        const { listService } = val;
        [...$(".dropdown-item")].forEach(element => {
          element.classList.remove("active");
        });

        $(`.${className}`).addClass("active");
        if (listService) {
          this.$emit("hoverMenu", this.sortArrayServices(val.listService));
          return;
        }
      }
    },
    concatName(item) {
      const itens = item.split(" ");
      if (itens.length > 1) {
        return itens.join("");
      }
      return itens[0];
    }
  }
};
</script>

<style scoped>
p {
  white-space: initial;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #2474b2;
}

.border_shadown {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.border_shadown:before {
  /* box-shadow: -3px 0px 7px -3px rgba(33, 33, 33, 0.2);
	content: " "; */
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0;
  width: 100%;
}

i {
  float: right;
}
.dropdown-item {
  padding: 0.25rem 0.5rem;
  background: #eaf3fa;
  border: 1px solid #ffffff;
  min-height: 60px;
  color: #2474b2;
  font-size: 12px;
  transition: all 0.2s linear;
  direction: ltr;
}

.newService {
  background-color: #fafafa;
  padding: 0.25rem 0.5rem;
  height: 60px;
  color: #ffffff;
  font-size: 12px;
  transition: all 0.2s linear;
  direction: ltr;
}

.dropdown-item:hover {
  background: #daebf8;
  color: #f38235;
}

.active {
  background: #daebf8;
  color: #f38235;
}

.menu {
  max-height: 70vh;
  overflow: auto;
  overflow-x: hidden;
  direction: rtl;
  width: 250px;
  background: #fff;
  border-radius: 6px 0 0 6px;
}

.menu::-webkit-scrollbar {
  width: 5px;
  background: #fff;
}

.menu::-webkit-scrollbar-thumb {
  background: #2474b2;
  border-radius: 4px;
}
.menu::-webkit-scrollbar-track {
  background: #98c4e6;
}

.controller {
  font-weight: 600;
  font-size: 11px;
  line-height: 21px;
  color: #f38235;
  margin: 0 10px 0 0;
}
.notificationControllerService {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9px;
  width: 9px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
}

.notificationController {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 19px;
  width: 19px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
}

.notificationAccompany {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6px;
  width: 6px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
  padding-left: 6px;
}

.elipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  #contentCont {
    min-width: 72%;
  }
  #contentCont p {
    margin-right: 16px !important;
  }
}
@media screen and (max-width: 400px) {
  .menuSubCont {
    width: 106%;
  }
  .menu {
    width: 213px;
  }
}
</style>
