import HTTP from '@/api_system'
export default {
  state: {},
  mutations: {},
  actions: {
    ExportDispatcher ({ getters }, params) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcher/ExportDispatcherJob`, params ,{  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    exportMaster({ getters }, filter) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcher/ExportDispatcherMasterJob`, filter, {  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
          .catch(err => {
            if (err.response.data.error.details) {
              reject(err.response.data.error.details)
            }
            else {
              reject("Algo deu errado.")
            }
        })
      })
    },
    exportClient({ getters }, filter) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/ServiceDispatcher/ExportDispatcherClientJob`, filter, {  headers: { Authorization: `bearer ${getters.getterToken}` } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
  getters: {}
};
