<template>
	<div>
		<div class="topo_box d-flex align-items-center justify-content-center">
			<h4 class="mb-0">
				Lista de instruções
				<i
					class="fas fa-play-circle ml-2 box_play"
					@click.prevent="showVideo()"
					v-if="link"
				></i>
			</h4>
		</div>
		<br>
		<div class="box_corpo">
			<div class="col-md-12 my-4" v-for="(item,index) in instructions" :key="index">
				<div
					class="box d-flex flex-column align-items-center py-2 justify-content-center"
					:class="item.spotlight ? 'boxDestaque' : ''"
				>
					<div class="col-md-10 mx-auto image" :id="`boxImg${index}`">
						<img 
							:src="getImg(item.image)" alt
              :class="{
                'imageBox': true,
                'img-enlarged-size': imageEnlarged,
              }"
							@error="removeBox(index)"
              @mouseover="enlargeImage(index)"
              @mouseout="shrinkImage"
						/>
					</div>
					<div class="img-box px-1">
						<h4 class="mb-0 text-center py-2">{{item.title}}</h4>
						<p class="mb-0">{{item.description}}</p>
					</div>
					<div :id="`sequence${index}`" class="position-absolute box_absoluto d-flex align-items-center justify-content-center">
						<span>{{item.sequence}}</span>
					</div>
				</div>
			</div>
		</div>
		<component :is="modalCp" v-if="link !=''" :link="link" @limpaIframe="limpaIframe" />
    <modalImage :image="image"/>
	</div>
</template>

<script>
	import modalIframe from "./_modalIframe";
  import modalImage from "./modal/modalImage.vue";
	export default {
		props: ["instructions", "link"],
		components: { 
      modalIframe,
      modalImage 
    },
		data() {
			return {
				modalCp: "",
        imageEnlarged: false,
        image: ""
			};
		},
		mounted() {
			var self = this;
			this.$nextTick(() => {
				window.addEventListener("resize", this.getWindowHeight);
			});
		},
		methods: {
			removeBox(index) {
				$(`#boxImg${index}`).css("display", "none");
			},
			showVideo() {
				this.modalCp = "modalIframe";
				this.$nextTick(() => {
					$("#modalIframe").modal("show");
				});
			},
			limpaIframe() {
				this.modalCp = "";
			},
			getWindowHeight() {
				var h = document.documentElement.clientHeight;
				this.minHeight = h - 140;
			},
      enlargeImage() {
        this.imageEnlarged = true;
      },
      shrinkImage() {
        this.imageEnlarged = false;
      }
		}
	};
</script>

<style scoped>
.imageBox {
	max-height: 110px;
	border-radius: 20px;
}
.img-enlarged-size {
  scale: 1.5;
  transition: all 0.2s;
}
i {
	cursor: pointer;
}
.topo_box h4 {
	font-size: 16px;
	color: #357eb8;
}
img {
	width: 100%;
}
.box {
	background-color: #ffffff;
	min-height: 210px;
	border-radius: 20px;
	border: solid 1px #357eb8;
	padding: 10px;
}
.box p {
	font-size: 14px;
	text-align: center;
	color: #868686;
}
.box h4 {
	font-size: 16px;
	color: #2474b2;
}
.box_absoluto {
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	width: 40px;
	background-color: #357eb8;
	top: -15px;
	text-align: center;
	height: 40px;
	border-radius: 50%;
}
.box_absoluto span {
	font-weight: 700;
	color: #ffffff;
}
.box_corpo {
	overflow-x: hidden;
	max-height: 600px;
	min-height: 250px;
	overflow-y: scroll;
}

.box_corpo::-webkit-scrollbar-track {
    background-color: #fff;
}
.boxDestaque {
	background-color: #e0a638;
	transition: all 0.2s linear;
}
.boxDestaque p {
	color: #ffffff;
	transition: all 0.2s linear;
}
.boxDestaque h4 {
	color: #ffffff;
	transition: all 0.2s linear;
}
.boxDestaque .box_absoluto {
	background-color: #e0a638;
	transition: all 0.2s linear;
}
</style>
