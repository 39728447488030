<template>
  <div>
    <div  v-if="(this.$route.path !== '/openCashRegister')">
      <div  v-if="(this.sizeWindow > 767)" class="login d-flex align-items-center justify-content-center grads">
      <div  :class="$store.getters.trocarComponentClose ? 'col-md-auto text-center': 'col-md-5 text-center' ">
      <!-- <div class="col-md-5 text-center"> -->
        <div :class="$store.getters.trocarComponentClose ?'box_modal_login px-4 row align-items-center py-4 mx-auto confirm' : 'box_modal_login px-4 row align-items-center py-4 mx-auto' ">
          <!-- <div class="mx-auto col-md-10"> -->
          <div :class="$store.getters.trocarComponentClose ? 'mx-auto col-md-auto' : 'mx-auto col-md-10'">
            <img
              alt="Vue logo"
              src="@/assets/image/Logo3-108.png"
              class="logo_modal_login mb-3"
              :class="logoSmall "
            />
            <transition name="vertical">
              <router-view />
            </transition>
      
          </div>
        </div>
        <div
          class="py-2"
          v-if="validaRota()"
          :style="$store.getters.loginAutenticado && $route.path == '/logout' ?'display:none' : ''"
        >
          <router-link :to="'/login'" class="outra-conta" v-if="this.sizeWindow >= 768">
            {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
            <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
          </router-link>
        </div>
    </div>
  </div>
  </div>
    <div v-if="(this.$route.path !== '/finishMobile')">
    <div v-if="(this.$route.path !== '/logout')">
    <div v-if="(this.$route.path !== '/abrir-caixa')">
    <div v-if="(this.$route.path !== '/openCashRegister')">
      <div v-if="(this.sizeWindow < 768)" class="login d-flex align-items-center justify-content-center gradsMobile">
        <div class="openCaixaCont">
          <div v-if="this.$route.path !== '/abrir-caixa'">
            <div v-if="this.$route.path !== '/logout'" class="titleAndSubCont">
              <h1 class="titleTxt"><strong>Olá,</strong></h1><br>
              <h2 class="subTitleTxt"><strong>Seja bem vindo!</strong></h2>
            </div>
          </div>
          <div v-if="this.$route.path === '/logout'" class="titleAndSubCont">
            <h2 class="titleTxtLogout"><strong>Nos vemos</strong></h2><br>
            <h2 class="subTitleTxt"><strong>em breve!</strong></h2>
          </div>
          <div  :class="$store.getters.trocarComponentClose ? 'col-md-auto text-center': 'col-md-5 text-center' " id="contMobileLogin">
          <!-- <div class="col-md-5 text-center"> -->
            <div :class="$store.getters.trocarComponentClose ?'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto confirm' : 'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto' ">
              <!-- <div class="mx-auto col-md-10"> -->
              <div :class="$store.getters.trocarComponentClose ? 'mx-auto col-md-auto' : 'mx-auto col-md-10'">
                <div v-if="this.$route.path !== '/abrir-caixa'">
                  <img v-if="this.$route.path !== '/senha'"
                    alt="Vue logo"
                    src="@/assets/image/Logo3-108.png"
                    class="logo_modal_loginMobile mb-3"
                    :class="logoSmall "
                  />
                </div>
                <transition name="vertical">
                  <router-view />
                </transition>
          
              </div>
            </div>
            <div
              class="py-2"
              v-if="validaRota()"
              :style="$store.getters.loginAutenticado && $route.path == '/logout' ?'display:none' : ''"
            >
              <router-link :to="'/login'" class="outra-conta" v-if="this.sizeWindow >= 768">
                {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
                <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    <div v-if="(this.$route.path === '/abrir-caixa')">
        <div v-if="(this.sizeWindow < 768)" class="login d-flex align-items-center justify-content-center gradsMobile">
          <div class="openCaixaCont">
            <img src="../../assets/image/banner/whiteLogo-61.png" class="whiteLogo">
            <div v-if="this.$route.path !== '/abrir-caixa'">
              <div v-if="this.$route.path !== '/logout'" class="titleAndSubCont">
                <h1 class="titleTxt"><strong>Olá,</strong></h1><br>
                <h2 class="subTitleTxt"><strong>Seja bem vindo!</strong></h2>
              </div>
            </div>
            <div v-if="this.$route.path === '/logout'" class="titleAndSubCont">
              <h2 class="titleTxtLogout"><strong>Nos vemos</strong></h2><br>
              <h2 class="subTitleTxt"><strong>em breve!</strong></h2>
            </div>
            <div  :class="$store.getters.trocarComponentClose ? 'col-md-auto text-center': 'col-md-5 text-center' " id="contMobileVend">
            <!-- <div class="col-md-5 text-center"> -->
              <div :class="$store.getters.trocarComponentClose ?'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto confirm' : 'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto' ">
                <!-- <div class="mx-auto col-md-10"> -->
                <div :class="$store.getters.trocarComponentClose ? 'mx-auto col-md-auto' : 'mx-auto col-md-10'">
                  <div v-if="this.$route.path !== '/abrir-caixa'">
                    <img v-if="this.$route.path !== '/senha'"
                      alt="Vue logo"
                      src="@/assets/image/Logo3-108.png"
                      class="logo_modal_loginMobile mb-3"
                      :class="logoSmall "
                    />
                  </div>
                  <transition name="vertical">
                    <router-view />
                  </transition>
            
                </div>
              </div>
              <div
                class="py-2"
                v-if="validaRota()"
                :style="$store.getters.loginAutenticado && $route.path == '/logout' ?'display:none' : ''"
              >
                <router-link :to="'/login'" class="outra-conta" v-if="this.sizeWindow >= 768">
                  {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
                  <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
                </router-link>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div v-if="(this.$route.path === '/openCashRegister')">
        <div v-if="(this.sizeWindow < 768)" class="login d-flex align-items-center justify-content-center gradsMobile">
          <div class="openCaixaCont">
            <img src="../../assets/image/banner/whiteLogo-61.png" class="whiteLogo">
            <div  :class="$store.getters.trocarComponentClose ? 'col-md-auto text-center': 'col-md-5 text-center' " id="contMobileVend">
            <!-- <div class="col-md-5 text-center"> -->
              <div :class="$store.getters.trocarComponentClose ?'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto confirm' : 'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto' ">
                <!-- <div class="mx-auto col-md-10"> -->
                <div :class="$store.getters.trocarComponentClose ? 'mx-auto col-md-auto' : 'mx-auto col-md-10'">
                  <div v-if="this.$route.path !== '/openCashRegister'">
                    <img v-if="this.$route.path !== '/senha'"
                      alt="Vue logo"
                      src="@/assets/image/Logo3-108.png"
                      class="logo_modal_loginMobile mb-3"
                      :class="logoSmall "
                    />
                  </div>
                  <transition name="vertical">
                    <router-view />
                  </transition>
            
                </div>
              </div>
              <div
                class="py-2"
                v-if="validaRota()"
                :style="$store.getters.loginAutenticado && $route.path == '/logout' ?'display:none' : ''"
              >
                <router-link :to="'/login'" class="outra-conta" v-if="this.sizeWindow >= 768">
                  {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
                  <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
                </router-link>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div v-if="($store.getters.getUser.profile === 3)">
    <div v-if="(this.$route.path === '/logout')">
        <div v-if="(this.sizeWindow < 768)" class="login d-flex align-items-center justify-content-center gradsMobile">
          <div class="openCaixaCont">
            <img src="../../assets/image/banner/whiteLogo-61.png" class="whiteLogo">
            <div  :class="$store.getters.trocarComponentClose ? 'col-md-auto text-center': 'col-md-5 text-center' " id="contMobileVend">
            <!-- <div class="col-md-5 text-center"> -->
              <div :class="$store.getters.trocarComponentClose ?'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto confirm' : 'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto' ">
                <!-- <div class="mx-auto col-md-10"> -->
                <div :class="$store.getters.trocarComponentClose ? 'mx-auto col-md-auto' : 'mx-auto col-md-10'">
                  <div v-if="this.sizeWindow >= 768">
                    <img v-if="this.$route.path !== '/senha'"
                      alt="Vue logo"
                      src="@/assets/image/Logo3-108.png"
                      class="logo_modal_loginMobile mb-3"
                      :class="logoSmall "
                    />
                  </div>
                  <transition name="vertical">
                    <router-view />
                  </transition>
            
                </div>
              </div>
              <div
                class="py-2"
                v-if="validaRota()"
                :style="$store.getters.loginAutenticado && $route.path == '/logout' ?'display:none' : ''"
              >
                <router-link :to="'/login'" class="outra-conta" v-if="this.sizeWindow >= 768">
                  {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
                  <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
                </router-link>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
    <div  v-if="(this.$route.path === '/finishMobile')">
      <div v-if="(this.sizeWindow < 768)" class="login d-flex align-items-center justify-content-center gradsMobile">
        <div class="openCaixaCont">
          <div class="titleAndSubCont">
            <h2 class="titleTxtLogout"><strong>Nos vemos</strong></h2><br>
            <h2 class="subTitleTxt"><strong>em breve!</strong></h2>
          </div>
          <div  :class="$store.getters.trocarComponentClose ? 'col-md-auto text-center': 'col-md-5 text-center' " id="contMobileLogin">
          <!-- <div class="col-md-5 text-center"> -->
            <div :class="$store.getters.trocarComponentClose ?'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto confirm' : 'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto' ">
              <!-- <div class="mx-auto col-md-10"> -->
              <div :class="$store.getters.trocarComponentClose ? 'mx-auto col-md-auto' : 'mx-auto col-md-10'">
                <div v-if="this.$route.path !== '/abrir-caixa'">
                  <img v-if="this.$route.path !== '/senha'"
                    alt="Vue logo"
                    src="@/assets/image/Logo3-108.png"
                    class="logo_modal_loginMobile mb-3"
                    :class="logoSmall "
                  />
                </div>
                <transition name="vertical">
                  <router-view />
                </transition>
          
              </div>
            </div>
            <div
              class="py-2"
              v-if="validaRota()"
              :style="$store.getters.loginAutenticado && $route.path == '/logout' ?'display:none' : ''"
            >
              <router-link :to="'/login'" class="outra-conta" v-if="this.sizeWindow >= 768">
                {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
                <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$store.getters.getUser.profile != 3">
    <div  v-if="(this.$route.path === '/logout')">
      <div v-if="(this.sizeWindow < 768)" class="login d-flex align-items-center justify-content-center gradsMobile">
        <div class="openCaixaCont">
          <div class="titleAndSubCont">
            <h2 class="titleTxtLogout"><strong>Nos vemos</strong></h2><br>
            <h2 class="subTitleTxt"><strong>em breve!</strong></h2>
          </div>
          <div  :class="$store.getters.trocarComponentClose ? 'col-md-auto text-center': 'col-md-5 text-center' " id="contMobileLogin">
          <!-- <div class="col-md-5 text-center"> -->
            <div :class="$store.getters.trocarComponentClose ?'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto confirm' : 'box_modal_loginMobile px-4 row align-items-center py-4 mx-auto' ">
              <!-- <div class="mx-auto col-md-10"> -->
              <div :class="$store.getters.trocarComponentClose ? 'mx-auto col-md-auto' : 'mx-auto col-md-10'">
                <div v-if="this.$route.path !== '/abrir-caixa'">
                  <img v-if="this.$route.path !== '/senha'"
                    alt="Vue logo"
                    src="@/assets/image/Logo3-108.png"
                    class="logo_modal_loginMobile mb-3"
                    :class="logoSmall "
                  />
                </div>
                <transition name="vertical">
                  <router-view />
                </transition>
          
              </div>
            </div>
            <div
              class="py-2"
              v-if="validaRota()"
              :style="$store.getters.loginAutenticado && $route.path == '/logout' ?'display:none' : ''"
            >
              <router-link :to="'/login'" class="outra-conta" v-if="this.sizeWindow >= 768">
                {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
                <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      logoSmall: "",
      sizeWindow: 0
    };
  },
 
  watch: {
    
    $route: function() {
      if ($("#app").is(".overlay_custom")) {
        $(".dropdown-menu").fadeOut(500);
        $(".sub_menu").removeAttr("style");

        $(".navegation_item.active_item").removeClass("active_item");
        $("#app").removeClass("overlay_custom");
      }
      if (this.$route.path == "/abrir-caixa" || this.$route.path == "/logout") {
        this.logoSmall = "logoSmall";
        this.$nextTick(() => {
          this.logoAnimator();
        });
      }
    },
  },
  methods: {
    gratientAnimator(gradient) {
      TweenMax.to(".grads", 1, {
        backgroundImage: gradient,
        repeat: -1,
        yoyo: true
      });
    },
    logoAnimator() {
      TweenMax.staggerTo(
        ".logoSmall",
        1,
        {
          opacity: 1
        },
        0.9
      );
    },
    validaRota() {
      switch (this.$route.path) {
        case "/senha":
          return true;
        case "/esqueci-a-senha":
          return true;
        case "/trocar-senha":
          return true;
        case "/logout":
          return true;
        default:
          return false;
      }
    },
    
  },
  mounted(){
    this.sizeWindow = $(window).width();
  }
  
};
</script>

<style scoped>
.grads {
  background: linear-gradient(to bottom, #f28236 0%, #e0a638 100%);
}
.gradsMobile{
  background-image: url('../../assets/image/banner/bannerLoginPageMobile.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.logo_modal_login {
  height: 70px;
  width: auto;
}
.logo_modal_loginMobile{
  height: 85px;
  width: auto;
  margin-bottom: 3rem!important;
  padding-bottom: 0!important;
}
.box_modal_login {
  /* background: #fff;
  border-radius: 20px;
  min-height: 400px;
  overflow: hidden;
  max-width: 570px; */
  background: #fff;
  border-radius: 20px;
  min-height: 400px;
  overflow: hidden;
  max-width: 480px;
}

.confirm {
  overflow: initial;
  max-width: 100%;
}

.reset {
  left: 0 !important;
}

.outra-conta {
  color: #fff;
  transition: all 0.2s linear;
}
.outra-conta:hover {
  color: #2474b2;
  cursor: pointer;
}
.logoSmall {
  height: 50px !important;
  opacity: 0;
  width: auto;
}
.login {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f28236+0,e0a638+100 */
  height: 100vh !important;

  overflow: hidden;
}
.openCaixaCont{
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
#contMobileLogin{
  overflow: hidden;
  position: absolute;
  bottom: 0;
  padding-left: 0!important;
  padding-right: 0!important;
  height: 56%;
}
#contMobileVend{
  overflow: hidden;
  position: absolute;
  bottom: 0;
  padding-left: 0!important;
  padding-right: 0!important;
  height: 80%;
}
.box_modal_loginMobile{
  background: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  min-height: 100%;
  overflow: hidden;
  max-width: 100%;
}
.titleTxt{
  color: #FFFF;
  font-size: 4.5rem;
}
.titleTxtLogout{
  color: #FFFF;
  font-size: 2.9rem;
}
.subTitleTxt{
  color: #fff;
  font-size: 2.9rem;
}
.titleAndSubCont{
  margin-top: 36%;
  margin-left: 5%;
}
.whiteLogo{
  position: absolute;
    top: 10%;
    left: 29%;
    width: 40%;
}
@media screen and (max-height:749px){
  #contMobileLogin{
    height: 65%!important;
  }
  .titleAndSubCont{
    margin-top: 11%!important;
  }
  .titleTxt{
    font-size: 4rem!important;
  }
  .subTitleTxt{
    font-size: 2.5rem!important;
  }
  #contMobileVend{
    height: 90%;
  }
  .whiteLogo{
    position: absolute;
    top: 1%;
    left: 30%;
    width: 35%;
  }
}
</style>