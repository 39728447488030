<template>
  <div id="GRDS-servico" class="menu">  
    <div
      class="dropdown-item py-1"
      v-for="(item, index) in listaServ"
      :key="index"
    >
      <div class="row servico"> 
        <div
          class="col-md-11"
          @click="redirectRouterService(item, index)"
        >
          <p class="mb-0">{{item.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["listaServ"],
  data() {
    return {
      disabled: false
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser.profile
    }
  },
  methods: {
    redirectRouterService(item, index) {
      if (this.$store.getters.get_tenant.type == 4) {
        this.$emit("showModalDegustacaoDropdownServico");
      } else {
        if(this.user !== 3) {
          this.toast("Para Realizar algum serviço acessa o perfil vendedor!", this.$toast) 
          return
        }
      }
    }
  },
};
</script>

<style scoped>
.dropdown-item {
  padding: 1.25rem 1.5rem;
  background-color: #ffffff;
  height: 50px;
  color: #868686;
  font-size: 12px;
  white-space: normal;
  transition: all 0.2s linear;
  direction: ltr;
  cursor: pointer;
  border-bottom: 1px solid #eaf3fa;
}
.dropdown-item:hover {
  background-color: #f38235;
  color: #ffffff;
}
.menu {
  max-height: 70vh;
  overflow: auto;
  overflow-x: hidden;
  /* direction: rtl; */
  width: 300px;
}
.menu::-webkit-scrollbar {
  width: 2px;
  background: #fff;
}

.menu::-webkit-scrollbar-thumb {
  background-color: #f38235;
}
.menu::-webkit-scrollbar-track {
  background-color: #fff;
}
.info {
  width: 20px;
}
.servico {
  padding-top: 1.25em;
  padding-bottom: 1.25em;
}
/* p {
  white-space: initial;
} */
@media screen and (max-width:767px){
  .menu{
    width: 206px!important;
  }
  .menu .dropdown-item{
    height: 75px!important;
  }
}
@media screen and (max-width:400px){
  .menu{
    width: 195px!important;
  }
  
}
</style>