<template>
    <div class="menuMobileSearch">
        <nav id="navMenuSearch">
            <div class="navHeaderSearch">
                <img @click="closeSearch"  src="@/assets/image/iconesMobile/closeOrangeIcon.svg" class="removeMenu">
            </div>
            <div class="navBodySearch">
                <div class="col-md-6 input_search_list" id="inputSearchServiceMobile">
                    <input
                        v-on:blur="funcToUnblur"
                        type="search"
                        class="header_input_search_mobile pl-3"
                        v-model="searchMobile"
                        placeholder="Digite o nome de algum serviço…"
                        autocomplete="off"
                        
                    />
                    <pesquisa @closeMenuFront="closeMenuFront" :servicosMobile="servicosMobile" />
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import pesquisa from '@/components/header/_dp_pesquisaMobile.vue';
export default {
    props:['configOpenSearch'],
    components:{
        pesquisa
    },
    data(){
        return{
            activeClassInput: "",
            searchMobile: "",
        }
    },
    computed: {
        servicosMobile() {          
            var searchKey = this.searchMobile
            return this.$store.getters.buscaService.filter(item => {
                var itemService = item.serviceName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
                var categoryName = item.categoryName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
                return (
                (searchKey.length > 0 &&
                    itemService.toUpperCase().includes(searchKey.toUpperCase())) ||
                (searchKey.length > 0 &&
                    categoryName.toUpperCase().includes(searchKey.toUpperCase()))
                );
            });
        }
    },
    methods:{
        openFuncSearch(){
            const menu = document.querySelector('#navMenuSearch')
            menu.classList.add('openSearch')
        },
        closeSearch(){
            this.searchMobile = ""
            this.$emit("turnFalseSearch")
            const menu = document.querySelector('#navMenuSearch')
            menu.classList.remove('openSearch')
        },
        funcToUnblur(){
        this.activeClassInput = "";
        $("#app").removeClass("overlay_custom");
        $(".search_dp_mobile").fadeOut(500);
        $("header").css({ "z-index": "3" }); 
        },
        closeMenuFront(){
            this.closeSearch()
        }
    },
    mounted(){
        var self = this;
    $("#app").click(function(e) {
      if ($(e.target).is(".overlay_custom")) {
        self.activeClassInput = "";

        $(".dropdown-menu").fadeOut(500);
        $(".sub_menu").removeAttr("style");
        $(".search_dp_mobile").fadeOut(500);
        $("header").css({ "z-index": "3" });
        $(".input_search_list").removeAttr("style");
        $(".navegation_item.active_item").removeClass("active_item");
        $("#app").removeClass("overlay_custom");
      }
    });
    $(".input_search_list").click(function(e) {
      e.target.focus();
      $(".dropdown-menu").fadeOut(200);
      $(".sub_menu").removeAttr("style");
      $(".search_dp_mobile").fadeOut(200);
      [...$(".search_dp_mobile")].forEach(element => {
      });
      if ($(".search_dp_mobile").css("display") != "block") {
        self.activeClassInput = "active_class_input";
        document.querySelector('#app').classList.add('overlay_custom')
        $("header").removeAttr("style");
        $(".search_dp_mobile").fadeIn(700);
      } else {
        self.activeClassInput = "";
        $("#app").removeClass("overlay_custom");
        $(".search_dp_mobile").fadeOut(500);
        $("header").css({ "z-index": "3" });
      }
    });
    },
    watch:{
        configOpenSearch(x){
            if(x === true){
                this.openFuncSearch()
            }
        },
        $route(x){
            if(x.path !== this.$router.push){
                this.closeSearch()
            }
        }
    },
    
}
</script>

<style scoped>


.logoNav{
    width: 50%;
}
.removeMenu{
    position: absolute;
    top: 2.7%;
    right: 4%;
    width: 7%;
}
.navHeaderSearch{
    margin-top: 5%;
    margin-left: 5%;
}
#navMenuSearch{
    z-index: 99999999999;
    min-height: 100vh;
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: .1s ease-in-out;
    background-color: #FFF;
}
a{
    margin-bottom: 3%!important;
    text-decoration: none;
}
.openSearch{
    left: 0!important;
}
.removeMenu{
    cursor: pointer;
}
@media screen and (min-width:768px){
    .menuMobileSearch{
        display: none;
    }
}
.navBodySearch{
    width: 100%;
}
input{
    width: 100%;
    border: 2px orange solid;
    height: 45px;
    border-radius: 10px;
}
#inputSearchServiceMobile{
    top: 55px;
}
</style>